import React from "react";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";

const HeaderThreeNoImage = ({title}) => {
    return <Typography
        variant={"h3"}
        component={"h3"}
        sx={{
            display: {xs: 'block', md: 'inline-block'},
            marginBottom: 0,
            fontFamily: '"Darker Grotesque"',
            fontSize: {xs: '1.25rem', sm: '1.75rem'},
            fontWeight: 'bold',
            textAlign: 'left',
            textShadow: 'none'
        }}
    >{title}</Typography>

}

export default HeaderThreeNoImage;
