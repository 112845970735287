import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {onValue} from "firebase/database";
import QRCode from 'qrcode.react';
import {GUEST_LIST_REF} from "../../common/constants";
import {Typography} from "@mui/material";

const PrintMeal = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        onValue(GUEST_LIST_REF, snap => {
            if (!snap.exists()) return;
            const newList = []
            snap.forEach(guestSnap => {
                newList.push(guestSnap.val());
            })
            setData(newList);
        })
    }, [])


    return <Box sx={{display: "flex", flexWrap: 'wrap'}}>
        {data
        .filter(d => !!d.meal && !d.refusal)
        .flatMap((guest) => guest.meal.map(data => ({...data, familyName: guest.name, key: guest.key})))
        .filter(guest => !guest?.child)
        .map((m, i) => (<Box sx={{
               width: t => `calc(33% - 2 * ${t.spacing(1)})`, m: 1, mb: '42px', justifyContent: 'center', display: 'flex'
            }}>
                <Box sx={{ display: "flex", flexDirection: "column",  alignItems: 'center',
                    border: 'solid 1px black', p:1, boxSizing: 'border-box' }}>
                    <Typography variant={"body1"}>Menüauswahl</Typography>
                    <QRCode
                        id={m.key}
                        value={`https://wedding.hotarek-ribel.at/meal?code=${m.key}`}
                        size={160}
                        level={"L"}
                        includeMargin={true}
                    />
                    <Typography variant={"body1"}>{m.familyName}</Typography>
                </Box>
            </Box>)
        )}
    </Box>
}

export default PrintMeal;
