// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAbM-tpvMdDr-0x2q7pAnQZ7o3lqFL4h3s",
    authDomain: "wedding-hotarek-ribel.firebaseapp.com",
    projectId: "wedding-hotarek-ribel",
    storageBucket: "wedding-hotarek-ribel.appspot.com",
    messagingSenderId: "183905820806",
    appId: "1:183905820806:web:177f53616b461227d23107",
    databaseURL: "https://wedding-hotarek-ribel-default-rtdb.europe-west1.firebasedatabase.app/",
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
