import React, {useEffect, useState} from "react";
import {Backdrop, Box, Button, IconButton, InputAdornment, LinearProgress, TextField} from "@mui/material";
import Block from "../../components/Block";
import Paragraph from "../../components/Paragraph";
import PagePaper from "../../components/PagePaper";
import {useAuth} from "../../components/AuthProvider";
import {child, onValue, set} from "firebase/database";
import {EMPTY_GUEST, GUEST_LIST_REF, THANK_YOU_REF} from "../../common/constants";
import Icon from "@mui/material/Icon";
import PageImage from "../../components/PageImage";
import mainPic from "../../assets/images/eventlocation-schloss-hof-wien.jpeg";
import HeaderOneImage from "../../components/HeaderOneImage";
import HeaderTwoImage from "../../components/HeaderTwoImage";
import {useParams} from "react-router-dom";
import ImageUploadButton from "../../components/ImageUploadButton";
import {getDownloadURL, getStorage, ref, uploadBytesResumable, uploadString} from "firebase/storage";
import FirebaseVideo from "../../components/FirebaseVideo";
import FirebaseImage from "../../components/FirebaseImage";

const storage = getStorage();

const EMPTY_PAGE = {
    title: "",
    //    name: "",
    text: "",
    image: "",
    video: "",
    key: ""
}

const ThankYou = () => {
    const {user, code} = useAuth();
    const {isAdmin} = user;
    const [pageData, setPageData] = useState({...EMPTY_PAGE});
    const [editPageData, setEditPageData] = useState({...EMPTY_PAGE});
    const [guestData, setGuestData] = useState(null);
    const [toggleAdmin, setToggleAdmin] = useState(true);
    const [editTitle, setEditTitle] = useState(false);
    const [editText, setEditText] = useState(false);
    const [backdrop, setBackdrop] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [selectedCode, setSelectedCode] = useState(code ?? "");
    const {key} = useParams();

    useEffect(() => {
        if (code && !isAdmin) {
            setSelectedCode(code);
            onValueFunctions(code);
        } else if (code && isAdmin && key) {
            setSelectedCode(key);
            onValueFunctions(key)
        }
    }, [code, isAdmin, key]);

    const onValueFunctions = (code) => {
        onValue(child(THANK_YOU_REF, code), snap => {
            if (!snap.exists()) return;
            const data = snap.val();
            setPageData(data);
            if (data.image) {
                const urlRef = ref(storage, data.image);
                getDownloadURL(urlRef).then(url => {
                    setImageUrl(url);
                });
            }
        })
        onValue(child(GUEST_LIST_REF, code), snap => {
            if (!snap.exists()) return;
            const data = snap.val();
            setGuestData({...EMPTY_GUEST, ...data});
        })
    }

    const editPage = async () => {
        if (!editPageData.key) editPageData.key = code;
        await set(child(THANK_YOU_REF, selectedCode), editPageData);
        setEditPageData({...EMPTY_PAGE});
        setEditTitle(false);
        setEditText(false);
    }

    const handleUploadClick = category => event => {
        const files = event.target.files;
        Array.from(files).forEach(file => {
            const refString = `images/thankYou/${selectedCode}/${file.name}`;
            pageData.image = refString;
            const storageRef = ref(storage, refString);
            if (file.type.includes("video")) {

                const _VIDEO = document.createElement("video");
                const _SOURCE = document.createElement("source");
                const _CANVAS = document.createElement("canvas");
                const _CTX = _CANVAS.getContext("2d");

                _SOURCE.type = file.type;
                _VIDEO.append(_SOURCE);
                _VIDEO.setAttribute('src', URL.createObjectURL(file));
                _VIDEO.setAttribute('crossorigin', "anonymous");

                // Load the video and show it
                _VIDEO.load();

                // Load metadata of the video to get video duration and dimensions
                _VIDEO.addEventListener('loadedmetadata', function() {
                    // Set canvas dimensions same as video dimensions
                    _CANVAS.width = _VIDEO.videoWidth;
                    _CANVAS.height = _VIDEO.videoHeight;
                });
                _VIDEO.currentTime = 0.001;
                _VIDEO.addEventListener('canplay', function() {
                    _CANVAS.style.display = 'inline';
                    _CTX.drawImage(_VIDEO, 0, 0, _VIDEO.videoWidth, _VIDEO.videoHeight);
                    const url = _CANVAS.toDataURL("image/png")

                    const name = file.name.split('.')[0];
                    const refStringPreview = `images/thankYou/${selectedCode}/preview_${name}.png`;
                    const storageRefPreview = ref(storage, refStringPreview);
                    pageData.image = refStringPreview;
                    pageData.video = refString;
                    uploadString(storageRefPreview, url, 'data_url').then((snapshot) => {
                        console.log('Uploaded a data_url string!');
                    });
                });
            }
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress)
                },
                (error) => {
                    setUploadProgress(0)
                    console.error(error);
                },
                async () => {
                    setUploadProgress(0);
                    await set(child(THANK_YOU_REF, selectedCode), pageData);
                }
            );
        })
    };


    return <Block>
        <PagePaper withImage>
            <PageImage alt={"Schloss Hof"} src={pageData?.image && imageUrl ? imageUrl : mainPic} noBottom={uploadProgress > 0} onClick={() => setBackdrop(true)}/>
            {isAdmin && toggleAdmin && <ImageUploadButton id={"upload-video"} handleUploadClick={handleUploadClick()} isFab sx={{
                position: 'absolute',
                top: t => t.spacing(2),
                right: t => t.spacing(2),
                zIndex: t => t.zIndex.drawer + 2
            }}/>}
            { pageData.video && <IconButton sx={{
                position: 'absolute',
                top: t => t.spacing(2),
                left: t => t.spacing(2),
                zIndex: t => t.zIndex.drawer + 2,
                color: 'white',
                backgroundColor: '#333',
                pointerEvents: 'none'
            }}><Icon>play_arrow</Icon></IconButton> }
            {uploadProgress > 0 && <LinearProgress sx={{mb: 4}} variant="determinate" value={uploadProgress}/>}
            <Box sx={{px: 4}}>

                <HeaderOneImage title={"Vielen Dank,"}/>
                <HeaderTwoImage title={guestData?.name ?? "Loading..."}/>

                <Paragraph important smallBottom>
                    {editTitle && isAdmin && toggleAdmin ? <TextField
                        sx={{mt: 2}}
                        autoComplete={"off"}
                        fullWidth
                        label={"Name"}
                        title={"Name"}
                        variant={"outlined"}
                        value={editPageData.title}
                        onChange={(e) => setEditPageData({...editPageData, title: e.target.value})}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton color={"primary"} onClick={editPage}><Icon>done</Icon></IconButton>
                                <IconButton onClick={() => {
                                    setEditTitle(false);
                                    setEditPageData({...EMPTY_PAGE})
                                }}><Icon>cancel</Icon></IconButton>
                            </InputAdornment>,
                        }}
                    /> : <Box>
                        {isAdmin && toggleAdmin && <IconButton onClick={() => {
                            setEditTitle(true);
                            setEditPageData({...EMPTY_PAGE, ...pageData})
                        }}><Icon>edit</Icon></IconButton>}
                        {pageData.title}
                    </Box>}
                </Paragraph>

                <Paragraph>
                    {editText && isAdmin && toggleAdmin ? <TextField
                        sx={{mt: 2}}
                        autoComplete={"off"}
                        fullWidth
                        label={"Dankes Text"}
                        title={"Dankes Text"}
                        variant={"outlined"}
                        multiline
                        rows={4}
                        value={editPageData.text}
                        onChange={(e) => setEditPageData({...editPageData, text: e.target.value})}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton color={"primary"} onClick={editPage}><Icon>done</Icon></IconButton>
                                <IconButton onClick={() => {
                                    setEditText(false);
                                    setEditPageData({...EMPTY_PAGE})
                                }}><Icon>cancel</Icon></IconButton>
                            </InputAdornment>,
                        }}
                    /> : <Box>
                        {isAdmin && toggleAdmin && <IconButton onClick={() => {
                            setEditText(true);
                            setEditPageData({...EMPTY_PAGE, ...pageData})
                        }}><Icon>edit</Icon></IconButton>}
                        {pageData.text}
                    </Box>}
                </Paragraph>
            </Box>

            {isAdmin && <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button variant={"text"} color={"info"} onClick={() => setToggleAdmin(b => !b)}><Icon>toggle</Icon> Toggle Admin View</Button>
            </Box>}
        </PagePaper>

        <Backdrop open={backdrop} sx={{p: 1, zIndex: t => t.zIndex.drawer + 3}} onClick={() => setBackdrop(false)}>
            {backdrop && pageData.video &&
            <FirebaseVideo
                firebaseRef={ref(storage, pageData.video)}/>}
            {backdrop && !pageData.video &&
            <FirebaseImage
                height
                firebaseRef={ref(storage, pageData.video)}
                alt={"Bild zu test"}/>
            }

        </Backdrop>
    </Block>
}

export default ThankYou;


