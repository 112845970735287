import React, {useEffect, useState} from "react";
import {getDownloadURL} from "firebase/storage";
import loadingGif from "../../assets/images/giphy.gif";

const FirebaseVideo = ({firebaseRef, videoRefList}) => {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let currentRef = firebaseRef;

        if (videoRefList) {
            const fileName = firebaseRef.name.split('.')[0].slice(8);
            const videoObject = videoRefList.find(f => f.name.includes(fileName))
            currentRef = videoObject.ref;
        }

        getDownloadURL(currentRef).then(res => {
            setUrl(res);
            setLoading(false)
        })

    }, [firebaseRef]);

    if (!url) return <img/>
    if (loading) return <img src={loadingGif}/>

    return <video style={{maxWidth: '100%', maxHeight: '100%'}} controls onClick={(e) => e.stopPropagation()}>
        <source src={url}/>
    </video>

}

export default FirebaseVideo;
