import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInAnonymously, signInWithEmailAndPassword, signOut} from "firebase/auth";
import {child, get, getDatabase, ref, set} from "firebase/database";
import {firebase} from "../../common/firebase";
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useSnackbar} from 'notistack';

const AuthContext = createContext(null);
const AuthProvider = ({children}) => {
    const {enqueueSnackbar} = useSnackbar();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loginLoading, setLoginLoading] = useState(false);
    const [inviteLoading, setInviteLoading] = useState(false);
    const [registerLoading, setRegisterLoading] = useState(false);
    const [code, setCode] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const db = getDatabase(firebase);
    const auth = getAuth(firebase);
    const permissionRef = ref(db, 'permissions');
    const guestsRef = ref(db, 'guests');

    const navigateBack = useCallback((id) => {
        const origin = location.state?.from?.pathname ?? (location.pathname !== "/" ? location.pathname : '/overview');
        if (!id) return navigate(origin);
        return navigate(origin);
    }, [navigate, location])


    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (!user) {
                setLoading(false);
                return setUser(null);
            }
            user.isAdmin = false;
            setUser(user);
            get(child(permissionRef, user.uid)).then(async snap => {
                try {
                    if (!snap.exists()) return setLoading(false);
                    user.isAdmin = snap.val().admin ?? false;
                    if (user.isAdmin) {
                        setCode("1OHZ1HKTMP");
                        const inviteSnap = await get(child(guestsRef, "1OHZ1HKTMP"));
                        const guestData = inviteSnap.val();
                        user.displayName = guestData.name;
                    }
                    setUser(user);
                    navigateBack();
                    setLoading(false);
                } catch (e) {
                    console.log(e);
                    setLoading(false);
                }
            }, e => setLoading(false))
        });
    }, [])

    useEffect(() => {
        if (!loading) {
            const urlCode = searchParams.get("code");
            if (!urlCode && !!code) {
                if (!!user) setSearchParams({code});
            } else if (!!urlCode && urlCode !== code) {
                setCode(urlCode);
                handleInternInvite(urlCode);
            }
        }
    }, [location, code, user, loading])

    const handleLogin = async (email, password) => {
        setLoginLoading(true);
        setErrorMessage(null);
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            setLoginLoading(false);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            setErrorMessage(`${errorCode} - ${errorMessage}`);
            enqueueSnackbar(`Login versuch fehlgeschlagen, falsches passwort? ${errorCode} - Fehlermeldung: ${errorMessage}`, {
                variant: 'error',
            });
            setLoginLoading(false)
        });
    };

    const handleLogout = () => {
        signOut(auth).then(() => {
            setUser(null);
            setCode(null);
            navigate("/", {state: {from: {pathname: "/overview"}}});
        })
    };

    const handleInvite = (id) => {
        setSearchParams({code: id});
    }

    const handleInternInvite = async (id) => {
        setInviteLoading(true);
        setErrorMessage(null);
        try {
            const keySnap = await get(child(guestsRef, `${id}/key`));
            if (!keySnap.exists()) return setErrorMessage('Irgendetwas ist schief gegangen, falscher code ?');
            const userCredential = await signInAnonymously(auth);
            const user = userCredential.user;
            const inviteSnap = await get(child(guestsRef, id));
            const guestData = inviteSnap.val();
            user.displayName = guestData.name;
            setUser(user);
            navigateBack(id);
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            setErrorMessage(`Irgendetwas ist schief gegangen, falscher code ?${errorCode} - Fehlermeldung: ${errorMessage}`);
            enqueueSnackbar(`Irgendetwas ist schief gegangen, falscher code ? ${errorCode} - Fehlermeldung: ${errorMessage}`, {
                variant: 'error',
            });
        } finally {
            setInviteLoading(false);
        }
    }

    const register = async (email, password) => {
        setRegisterLoading(true);
        setErrorMessage(null);
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            setUser(userCredential.user);
            await set(child(permissionRef, user.uid), {admin: false});
            enqueueSnackbar('Registriern war erfolgreich', {
                variant: 'succes',
            });
            setRegisterLoading(false);
            return true;
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            setErrorMessage(`${errorCode} - ${errorMessage}`);
            enqueueSnackbar('Beim registrieren ist leider etwas schiefgegenagen', {
                variant: 'error',
            });
            setRegisterLoading(false);
            return false;
        }
    }

    const value = {
        userLoading: loading,
        user,
        code,
        loginLoading,
        inviteLoading,
        registerLoading,
        onLogin: handleLogin,
        onLogout: handleLogout,
        onInvite: handleInvite,
        errorMessage,
        register
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};
