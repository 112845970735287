import React from "react";
import {Box} from "@mui/material";

const PageImage = ({src, alt, noBottom, onClick}) => {
    return <Box sx={{width: t => `calc(100%)`, position: 'relative', marginBottom: noBottom ? 0 : 4}} onClick={onClick}>
        <img style={{width: '100%'}} src={src} alt={alt}/>
    </Box>

}
export default PageImage;
