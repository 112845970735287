import React, {useEffect, useState} from "react";
import {Box, TextField, FormGroup, FormControlLabel, Button, FormLabel, Paper, Radio, styled} from "@mui/material";
import {useAuth} from "../../components/AuthProvider";
import { EMPTY_GUEST, GUEST_LIST_REF} from "../../common/constants";
import {child, onValue, set} from "firebase/database";
import {useSnackbar} from 'notistack';
import Block from "../../components/Block";
import {hasPromise} from "../../common/statelessFunctions";
import PagePaper from "../../components/PagePaper";
import HeaderOneNoImage from "../../components/HeaderOneNoImage";
import HeaderTwoNoImage from "../../components/HeaderTwoNoImage";
import Paragraph from "../../components/Paragraph";

const StyledInput = styled(TextField)(({theme}) => ({
    marginBottom: theme.spacing(2),
}))

const Taxi = () => {
    const {enqueueSnackbar} = useSnackbar();
    const {code} = useAuth();
    const [guestData, setGuestData] = useState(EMPTY_GUEST);

    useEffect(() => {
        if (code) {
            onValue(child(GUEST_LIST_REF, code), snap => {
                if (!snap.exists()) return;
                const data = snap.val();
                setGuestData({...EMPTY_GUEST, ...data});
                if (!data.taxi) set(child(GUEST_LIST_REF, `${data.key}/taxi`), EMPTY_GUEST.taxi).then();
            })
        }
    }, [code]);

    const handleChangeFeedback = (event) => {
        const {value} = event.target;
        const nextGuestData = {...guestData};
        nextGuestData.taxi.feedback = value;
        return setGuestData({...nextGuestData})
    }

    const handleChangeTaxi = (value) => (e) => {
        const {name} = e.target;
        const nextGuestData = {...guestData};
        nextGuestData.taxi[name] = value;
        return setGuestData({...nextGuestData})
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await set(child(GUEST_LIST_REF, `${guestData.key}/taxi`), guestData.taxi)
            enqueueSnackbar('Gespeichert und abgeschickt', {
                variant: 'success',
            })
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            enqueueSnackbar('Fehler beim Speichern und Senden', {
                variant: 'error',
            })
        }
    }

    return <Block>
        <PagePaper>
            <HeaderOneNoImage title={"ABREISE"}/>
            <HeaderTwoNoImage title={"von Schlosshof"}/>
            <Paragraph>
                Liebe Freunde und liebe Familie,
            </Paragraph>
            <Paragraph>
                da einige, die auf unserer Website angebotenen Unterkünfte, gerne in Anspruch genommen haben und doch auch alkoholische Getränke trinken
                möchten, kam die Idee auf Sammeltaxis zu gründen.
            </Paragraph>
            <Paragraph>
                Nun möchten wir hier fragen, wer denn gerne in einem Taxi mitfahren möchte.
            </Paragraph>

            <Paragraph>
                Je nachdem wie viele Rückmeldungen wir bis spätestens 17.07.2022 erhalten, werden wir hier Informationen zu Taxiunternehmen, Preisen & Co.
                bekanntgeben.
            </Paragraph>

            {hasPromise(guestData) !== 2 && <Box>
                <Paragraph important>
                    Leider hast du uns keine Zusage gegeben, deswegen darfst du jetzt auch nicht mit abstimmen!
                </Paragraph>
            </Box>}

            {guestData?.taxi && hasPromise(guestData) === 2 && <Box component={"form"} noValidate autoComplete="on" onSubmit={handleSubmit}>
                <Paper elevation={2} sx={{p: 2, boxSizing: 'border-box', marginTop: 1, marginBottom: 2}}>
                    <FormGroup>
                        <FormLabel sx={{fontSize: '1.3em'}}>Gib hier bitte an ..</FormLabel>
                        <FormControlLabel
                            control={<Radio name={"selected"} checked={guestData.taxi.selected === "yes"} onChange={handleChangeTaxi("yes")}/>}
                            label="Ja, bitte. Ich / Wir möchte /n ein Taxi bestellen."
                            labelPlacement="end"/>
                        <FormControlLabel control={<Radio name={"selected"} checked={guestData.taxi.selected === "no"} onChange={handleChangeTaxi("no")}/>}
                                          label="Nein, ich / wir brauche /n keines. Danke!"
                                          labelPlacement="end"/>
                    </FormGroup>
                    <FormGroup sx={{marginTop: 2}}>
                        <StyledInput
                            id={`feedback`}
                            label={"Weiter Infos, z.B.: Unterkunft adresse"}
                            type="text"
                            multiline
                            rows={3}
                            name="feedback"
                            value={guestData.taxi.feedback}
                            onChange={handleChangeFeedback}
                            variant="outlined"
                            fullWidth
                        />
                    </FormGroup>
                </Paper>
                <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', gap: 16, marginTop: 16}}>
                    <Button sx={{minWidth: 160}} variant={"outlined"} color={"primary"} type={"submit"}>Daten Speichern und Senden</Button>
                </div>
            </Box>}
        </PagePaper>
    </Block>
}

export default Taxi;
