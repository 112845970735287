import React, {useEffect, useState} from "react";
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField
} from "@mui/material";
import Block from "../../components/Block";
import HeaderOneNoImage from "../../components/HeaderOneNoImage";
import HeaderTwoNoImage from "../../components/HeaderTwoNoImage";
import Paragraph from "../../components/Paragraph";
import PagePaper from "../../components/PagePaper";
import {useAuth} from "../../components/AuthProvider";
import {customAlphabet} from "nanoid";
import {child, onValue, set} from "firebase/database";
import {THANK_LIST_REF} from "../../common/constants";
import Icon from "@mui/material/Icon";

const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 10)

const EMPTY_PERSON = {
    name: "",
    thanks: "",
    key: ""
}

const Thanks = () => {
    const {user} = useAuth();
    const {isAdmin} = user;
    const [persons, setPersons] = useState([]);
    const [person, setPerson] = useState({...EMPTY_PERSON});
    const [editModal, setEditModal] = useState(false);
    const [toggleAdmin, setToggleAdmin] = useState(true);

    useEffect(() => {
        onValue(THANK_LIST_REF, snap => {
            if (!snap.exists()) return;
            const newList = []
            snap.forEach(thankSnap => {
                newList.push(thankSnap.val());
            })
            setPersons(newList);
        })
    }, [])

    const addPerson = async () => {
        const newPerson = {...EMPTY_PERSON, ...person};
        newPerson.key = nanoid();
        await set(child(THANK_LIST_REF, newPerson.key), newPerson);
        setPerson({...EMPTY_PERSON});
        setEditModal(false);
    }

    const editPerson = async () => {
        await set(child(THANK_LIST_REF, person.key), person);
        setPerson({...EMPTY_PERSON});
        setEditModal(false);
    }

    const deletePerson = async (key) => {
        if (window.confirm(`Sicher den eintrag ${key} löschen?`)){
            await set(child(THANK_LIST_REF, key), null);
            setPerson({...EMPTY_PERSON});
            setEditModal(false);
        }
    }

    return <Block>
        <PagePaper>
            <HeaderOneNoImage title={"Vielen Dank"}/>
            <HeaderTwoNoImage title={`Diverse kurze danksagungen`}/>
            <Paragraph>
                Im nachheinein betrachtet haben wir viele Personen bei unserer Dankes rede übersprungen und so wollen wir sie hier zumindest noch ein mal
                Erwähnen
            </Paragraph>
            <List>
                {persons.map((p) => (
                    <ListItem key={p.key}>
                        {isAdmin && toggleAdmin && <ListItemIcon>
                            <IconButton onClick={() => {
                                setEditModal(true);
                                setPerson({...p})
                            }}><Icon>edit</Icon></IconButton>
                        </ListItemIcon>
                        }
                        <ListItemText primary={p.name} secondary={p.thanks}/>
                    </ListItem>
                ))}
                {isAdmin && toggleAdmin && <ListItemButton onClick={() => {
                    setEditModal(true);
                    setPerson({...EMPTY_PERSON})
                }}>
                    {isAdmin  && toggleAdmin && <ListItemIcon>
                        <Icon>add</Icon>
                    </ListItemIcon>
                    }
                    <ListItemText primary={"Neuer Eintrag"}/>
                </ListItemButton>}
            </List>

            <Dialog onClose={() => setEditModal(false)} open={editModal}>
                <DialogTitle>Eintrag Bearbeiten</DialogTitle>
                <DialogContent>
                    <TextField
                        sx={{mt: 2}}
                        autoComplete={"off"}
                        fullWidth
                        label={"Name"}
                        title={"Name"}
                        variant={"outlined"}
                        value={person.name}
                        onChange={(e) => setPerson({...person, name: e.target.value})}
                    />

                    <TextField
                        sx={{mt: 2}}
                        autoComplete={"off"}
                        fullWidth
                        label={"Dankes Text"}
                        title={"Dankes Text"}
                        variant={"outlined"}
                        multiline
                        rows={4}
                        value={person.thanks}
                        onChange={(e) => setPerson({...person, thanks: e.target.value})}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} onClick={() => {
                        setEditModal(false);
                        setPerson({...EMPTY_PERSON})
                    }}><Icon>cancel</Icon> Abbrechen</Button>
                    <Button variant={"contained"} color={"error"} onClick={() => {
                        setEditModal(false);
                        deletePerson(person.key)
                    }}><Icon>delete</Icon> Löschen</Button>
                    <Button variant={"contained"} onClick={() => person.key ? editPerson() : addPerson()}><Icon>done</Icon> Speichern</Button>
                </DialogActions>

            </Dialog>
            { isAdmin && <Box sx={{display: "flex", justifyContent: "flex-end"}}>
            <Button variant={"text"} color={"info"} onClick={() => setToggleAdmin(b =>!b)} ><Icon>toggle</Icon> Toggle Admin View</Button>
            </Box> }

        </PagePaper>
    </Block>
}

export default Thanks;
