import React, {useEffect, useState} from "react";
import {
    Avatar, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, List, ListItem, ListItemAvatar,
    ListItemText, MenuItem, Paper, Select, Typography, Box
} from "@mui/material";


const getStylingFor = (number) => {
    if(number === 0) return {position: 'absolute', top: -20, left: 225, whiteSpace: 'nowrap'};
    if(number === 1) return {position: 'absolute', top: 20, left: 320, whiteSpace: 'nowrap'};
    if(number === 2) return {position: 'absolute', top: 80, left: 350, whiteSpace: 'nowrap'};
    if(number === 3) return {position: 'absolute', top: 150, left: 355, whiteSpace: 'nowrap'};
    if(number === 4) return {position: 'absolute', top: 210, left: 325, whiteSpace: 'nowrap'};
    if(number === 5) return {position: 'absolute', bottom: -20, right: 25, textAlign: 'right', whiteSpace: 'nowrap'};
    if(number === 6) return {position: 'absolute', bottom: 20, right: 120, textAlign: 'right', whiteSpace: 'nowrap'};
    if(number === 7) return {position: 'absolute', bottom: 80, right: 150, textAlign: 'right', whiteSpace: 'nowrap'};
    if(number === 8) return {position: 'absolute', bottom: 150, right: 150, textAlign: 'right', whiteSpace: 'nowrap'};
    if(number === 9) return {position: 'absolute', bottom: 210, right: 120, textAlign: 'right', whiteSpace: 'nowrap'};
    return {position: 'absolute', bottom: (-20 * (number - 9))}
}

export const Tabel = ({persons, number}) => {

    if(!persons || persons.length === 0) return null;

    return <Box sx={{position: 'relative'}}>
        <Box sx={{mb: '70px' ,ml: '100px',width: 250, height: 250, borderRadius: '100%', border: 'solid 1px black', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
            Tisch {number} <br />
            {persons?.length} Personen
        </Box>
        {persons?.map((p,i) => (<Box sx={getStylingFor(i)} key={i}>{p.fullName}</Box>))}
    </Box>
}

