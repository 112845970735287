import React from "react";
import Block from "../../components/Block";
import PagePaper from "../../components/PagePaper";


const Impressum = () => {
    return <Block>
        <PagePaper>
            Impressum
        </PagePaper>
    </Block>
}

export default Impressum;
