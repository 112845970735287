import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Backdrop, Box, Button, Chip, Fab, Icon, IconButton, ImageList, ImageListItem, InputAdornment, LinearProgress, List, ListItem, TextField, Typography
} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Block from "../../components/Block";
import PagePaper from "../../components/PagePaper";
import {getDownloadURL, getMetadata, getStorage, listAll, ref, uploadBytes, uploadBytesResumable, uploadString} from "firebase/storage";
import {useAuth} from "../../components/AuthProvider";
import FirebaseImage from "../../components/FirebaseImage";
import {child, onValue, set} from "firebase/database";
import {GALLERY_REF} from "../../common/constants";
import FirebaseImageMeta from "../../components/FirebaseImageMeta";
import FirebaseVideo from "../../components/FirebaseVideo";
import {useNavigation} from "../../components/NavigationProvider";
import ImageUploadButton from "../../components/ImageUploadButton";
import FirebaseVideoMeta from "../../components/FirebaseVideoMeta";
import HeaderOneNoImage from "../../components/HeaderOneNoImage";
import Paragraph from "../../components/Paragraph";
import HeaderOneImage from "../../components/HeaderOneImage";
import HeaderTwoImage from "../../components/HeaderTwoImage";
import HeaderThreeNoImage from "../../components/HeaderThreeNoImage";
import {saveAs} from 'file-saver/dist/FileSaver';
import * as JSZip from 'jszip';

const storage = getStorage();

const getItemsFromRef = async (ref) => {
    let items = [];
    try {
        const res = await listAll(ref);
        if (res.items.length > 0) items = [...items, ...res.items];
        if (res.prefixes.length > 0) {
            for (ref of res.prefixes) {
                const newItems = await getItemsFromRef(ref);
                items = [...items, ...newItems];
            }
        }
    } catch (e) {
        console.error(e);
    }
    return items;
}

const getUploader = (path) => {
    return `#${path.split('/').filter(t => !t.includes('images'))[0]}`;
}

const filterForLabel = (str) => (item) => {
    return getUploader(item.fullPath).toLowerCase().includes(str.toLowerCase()) || item.customMetadata.tags.toLowerCase().includes(str.toLowerCase());
}

const filterForTags = tags => item => {
    if (tags.length === 0) return true;
    const inCategory = tags.some(t => item.customMetadata?.category?.toLowerCase() === t.toLowerCase());
    const inName = tags.some(t => getUploader(item.fullPath).toLowerCase() === '#' + t.toLowerCase());
    const inTags = tags.some(t => item.customMetadata?.tags?.toLowerCase().split('#').indexOf(t.toLowerCase()) > -1);

    return inCategory || inName || inTags;
}

const Gallery = () => {
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedRef, setSelectedRef] = useState(null);
    const [imageRefListAll, setImageRefListAll] = useState([]);
    const [imageRefList, setImageRefList] = useState([]);
    const [imageRefListTrauung, setImageRefListTrauung] = useState([]);
    const [imageRefListAgape, setImageRefListAgape] = useState([]);
    const [imageRefListEssen, setImageRefListEssen] = useState([]);
    const [imageRefListParty, setImageRefListParty] = useState([]);
    const [imageRefListBuch, setImageRefListBuch] = useState([]);
    const [imageRefListBox, setImageRefListBox] = useState([]);
    const [imageListLoading, setImageListLoading] = useState(true);
    const [searchArray, setSearchArray] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [uploadCounter, setUploadCounter] = useState(0);
    const [finishUploadCounter, setFinishUploadCounter] = useState(0);
    const [uploadPercent, setUploadPercent] = useState(0);
    const [uploadPercentMax, setUploadPercentMax] = useState(0);
    const [counter, setCounter] = useState(0);
    const {user} = useAuth();
    const testRef = useRef();
    const searchRef = useRef();
    const imageRefListAsObject = useRef({
        missing: [],
        trauung: [],
        agape: [],
        essen: [],
        party: [],
        buch: [],
        box: [],
    });
    const displayImageCountAsObject = useRef({
        missingCount: 0,
        trauungCount: 0,
        agapeCount: 0,
        essenCount: 0,
        partyCount: 0,
        buchCount: 0,
        boxCount: 0,
    });
    const loader = useRef(null);
    const {hideNavigation, showNavigation} = useNavigation();


    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            const {missing, trauung, agape,party,essen,box,buch} = imageRefListAsObject.current;
            const {missingCount, trauungCount, agapeCount, essenCount, partyCount, boxCount, buchCount} = displayImageCountAsObject.current;

            if (trauungCount < trauung.length) {
                displayImageCountAsObject.current.trauungCount = trauungCount + 25;
            } else if (agapeCount < agape.length) {
                displayImageCountAsObject.current.agapeCount = agapeCount + 25;
            } else if (essenCount < essen.length) {
                displayImageCountAsObject.current.essenCount = essenCount + 25;
            } else if (partyCount < party.length) {
                displayImageCountAsObject.current.partyCount = partyCount + 25;
            } else if (buchCount < buch.length) {
                displayImageCountAsObject.current.buchCount = buchCount + 25;
            } else if (boxCount < box.length) {
                displayImageCountAsObject.current.boxCount = boxCount + 25;
            } else if (missingCount < missing.length) {
                displayImageCountAsObject.current.missingCount = missingCount + 25;
            }
            setCounter((prev) => prev + 1);
        }
    }, []);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) observer.observe(loader.current);
    }, [handleObserver]);

    useEffect(() => {
        if (selectedRef) {
            hideNavigation();
        } else {
            showNavigation();
        }
    }, [selectedRef])

    useEffect(() => {
        onValue(GALLERY_REF, snap => {
            if (!snap.exists()) return;
            const lastUpdater = snap.val().update.split('#')[1]
            if (imageRefList.length === 0) {
                const imagesRef = ref(storage, 'images')

                getItemsFromRef(imagesRef).then(itemsList => {
                    const promiseList = itemsList.map(ref => getMetadata(ref));
                    Promise.all(promiseList).then(fullMetaList => {
                        setImageRefListAll(fullMetaList.filter(m => !m.name.includes("250x250")));
                        const metaList = fullMetaList.filter(m => m.name.includes("250x250"));
                        const listTrauung = metaList.filter(m => m.customMetadata?.category === "trauung");
                        const listAgape = metaList.filter(m => m.customMetadata?.category === "agape");
                        const listEssen = metaList.filter(m => m.customMetadata?.category === "essen");
                        const listParty = metaList.filter(m => m.customMetadata?.category === "party");
                        const listBuch = metaList.filter(m => m.customMetadata?.category === "buch");
                        const listBox = metaList.filter(m => m.customMetadata?.category === "box");
                        const listMissing = metaList.filter(m => !m.customMetadata?.category);

                        if (listTrauung.length > 0 && listTrauung.length > 10) {
                            displayImageCountAsObject.current = {...displayImageCountAsObject.current , trauungCount: 50}
                        } else if (listAgape.length > 0 && listAgape.length > 10) {
                            displayImageCountAsObject.current = {...displayImageCountAsObject.current , trauungCount: listTrauung.length , agapeCount: 50}
                        } else if (listEssen.length > 0 && listEssen.length > 10) {
                            displayImageCountAsObject.current = {...displayImageCountAsObject.current , trauungCount: listTrauung.length , agapeCount: listAgape.length, essenCount: 50}
                        } else if (listParty.length > 0 && listParty.length > 10) {
                            displayImageCountAsObject.current = {...displayImageCountAsObject.current, trauungCount: listTrauung.length , agapeCount: listAgape.length, essenCount: listEssen.length, partyCount: 50}
                        } else if (listBuch.length > 0 && listBuch.length > 10) {
                            displayImageCountAsObject.current = {
                                ...displayImageCountAsObject.current,
                                trauungCount: listTrauung.length ,
                                agapeCount: listAgape.length,
                                essenCount: listEssen.length,
                                partyCount: listParty.length,
                                buchCount: 50
                            }
                        } else if (listBox.length > 0 && listBox.length > 10) {
                            displayImageCountAsObject.current = {
                                ...displayImageCountAsObject.current,
                                trauungCount: listTrauung.length ,
                                agapeCount: listAgape.length,
                                essenCount: listEssen.length,
                                partyCount: listParty.length,
                                buchCount: listBuch.length,
                                boxCount: 50
                            }
                        } else if (listMissing.length > 0) {
                            displayImageCountAsObject.current = {
                                ...displayImageCountAsObject.current,
                                trauungCount: listTrauung.length ,
                                agapeCount: listAgape.length,
                                essenCount: listEssen.length,
                                partyCount: listParty.length,
                                buchCount: listBuch.length,
                                boxCount: listBox.length,
                                missingCount: 50,
                            }
                        }

                        setImageRefListTrauung(listTrauung);
                        setImageRefListAgape(listAgape);
                        setImageRefListEssen(listEssen);
                        setImageRefListParty(listParty);
                        setImageRefListBuch(listBuch);
                        setImageRefListBox(listBox);
                        setImageRefList(listMissing);

                        imageRefListAsObject.current = {
                            missing: listMissing,
                            trauung: listTrauung,
                            agape: listAgape,
                            essen: listEssen,
                            party: listParty,
                            buch: listBuch,
                            box: listBox,
                        }

                        setImageListLoading(false);
                    })
                })
            }
            if (lastUpdater && imageRefList.length > 0) {
                const imagesRef = ref(storage, 'images/' + lastUpdater);
                getItemsFromRef(imagesRef).then(itemsList => {
                    const promiseList = itemsList.map(ref => getMetadata(ref));
                    Promise.all(promiseList).then(metaList => {
                        const clearedList = imageRefList.filter(element => !element.fullPath.includes(`/${lastUpdater}`))
                        setImageRefList([...clearedList, ...metaList]);
                        setImageListLoading(false);
                    })
                })
            }
            // Info: it is possible to be no last Updater in General not in this case
        })
    }, []);

    const handleUploadClick = category => event => {
        const files = event.target.files;
        let counter = 0;
        let counterFinish = 0;
        let uploadObject= {};
        Array.from(files).forEach(file => {
            const refString = `images/${user.displayName}/${file.name}`;
            const storageRef = ref(storage, refString);
            const metadata = {
                customMetadata: {
                    'tags': '#flonaforever',
                    category
                }
            };
            counter += 100
            setUploadPercentMax(counter);
            setUploadCounter(counter);
            uploadObject[refString] = 0;
            if (file.type.includes("video")) {

                const _VIDEO = document.createElement("video");
                const _SOURCE = document.createElement("source");
                const _CANVAS = document.createElement("canvas");
                const _CTX = _CANVAS.getContext("2d");

                _SOURCE.type = file.type;
                _VIDEO.append(_SOURCE);
                _VIDEO.setAttribute('src', URL.createObjectURL(file));
                _VIDEO.setAttribute('crossorigin', "anonymous");

                // Load the video and show it
                _VIDEO.load();

                // Load metadata of the video to get video duration and dimensions
                _VIDEO.addEventListener('loadedmetadata', function() {
                    // Set canvas dimensions same as video dimensions
                    _CANVAS.width = _VIDEO.videoWidth;
                    _CANVAS.height = _VIDEO.videoHeight;
                });
                _VIDEO.currentTime = 0.001;
                _VIDEO.addEventListener('canplay', function() {
                    _CANVAS.style.display = 'inline';
                    _CTX.drawImage(_VIDEO, 0, 0, _VIDEO.videoWidth, _VIDEO.videoHeight);
                    const url = _CANVAS.toDataURL("image/png")

                    const name = file.name.split('.')[0];
                    const refStringPreview = `images/${user.displayName}/preview_${name}.png`;
                    const storageRefPreview = ref(storage, refStringPreview);
                    uploadString(storageRefPreview, url, 'data_url', metadata).then((snapshot) => {
                        console.log('Uploaded a data_url string!');
                    });
                });
            }
            const uploadTask = uploadBytesResumable(storageRef, file, metadata);

            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    uploadObject[refString] = progress;
                    setUploadPercent(Object.keys(uploadObject).reduce((a,k) => uploadObject[k] + a, 0));
                },
                (error) => {
                    counter -= 100
                    console.error(error);
                    setUploadCounter(counter);
                    setUploadPercentMax(counter);
                    delete uploadObject[refString];
                },
                () => {
                    counterFinish += 100;
                    setFinishUploadCounter(counterFinish);
                    if(counterFinish === counter){
                        setFinishUploadCounter(0);
                        setUploadCounter(0)
                        setUploadPercentMax(0);
                        setUploadPercent(0);
                        uploadObject = {};
                    }
                    set(child(GALLERY_REF, 'update'), (new Date()).toISOString() + `#${user.displayName}`)
                    .catch(e => console.error(e));
                }
            );
        })
    };

    const startDownload = async (e) => {
        console.log('test');
        e.stopPropagation();
        const zipFile = new JSZip();
        try {
            const url = await getDownloadURL(selectedRef);
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
                console.log(selectedRef);
                zipFile.file("test.jpg", blob, {binary: true});
                //create a file from the returned blob
                /*const file = new File([blob], "image name", {type: blob.type});
                //grab the a tag
                const a1 = document.getElementById('tagID');
                //set the download attribute of the a tag to the name stored in the file
                a1.download = file.name;
                //generate a temp url to host the image for download
                a1.href = URL.createObjectURL(file);*/
                zipFile.generateAsync({type: 'blob'}).then(content => {
                    saveAs(content, 'test.zip');
                });
            };
            xhr.open('GET', url);
            xhr.send();
        } catch (e) {
            console.error(e);
        }
    }

    const addSearchTag = e => {
        e.preventDefault();
        const data = new FormData(e.target)
        const json = Object.fromEntries(data);
        const str = json["searchTag"];
        searchArray.push(str);
        setSearchArray([...searchArray]);
        e.target.reset();
    }

    const removeSearchTag = tagName => e => {
        setSearchArray(searchArray.filter(e => e !== tagName));
    }

    const normalise = (MAX, value) => ((value) * 100) / (MAX);

    const nextImage = (ref) => (e) => {
        e.stopPropagation();
        const currentList = [
            ...imageRefListAsObject.current.trauung,
            ...imageRefListAsObject.current.agape,
            ...imageRefListAsObject.current.essen,
            ...imageRefListAsObject.current.box,
            ...imageRefListAsObject.current.buch,
        ]
        const index = currentList.findIndex(i => i.ref.fullPath === ref.fullPath);
        if(index > -1 && (currentList.length-1) !== index) return setSelectedRef(currentList[index+1].ref);
        if(index > -1 && (currentList.length-1) === index) return setSelectedRef(currentList[0].ref);
    }

    const prevImage = (ref) => (e) => {
        e.stopPropagation();
        const currentList = [
            ...imageRefListAsObject.current.trauung,
            ...imageRefListAsObject.current.agape,
            ...imageRefListAsObject.current.essen,
            ...imageRefListAsObject.current.box,
            ...imageRefListAsObject.current.buch,
        ]
        const index = currentList.findIndex(i => i.ref.fullPath === ref.fullPath);
        if(index > -1 && index !== 0) return setSelectedRef(currentList[index-1].ref);
        if(index > -1 && index === 0) return setSelectedRef(currentList[currentList.length-1].ref);
    }

    return <Block>
        <PagePaper ref={testRef}>
            <HeaderOneNoImage title={"Fotogallerie"}/>
            <HeaderOneImage title={"Hochzeitsfotos,"}/>
            <HeaderTwoImage title={"vom 11.8.2022"}/>
            <Paragraph>
                Unser Tag in Bildern für die Ewigkeit und nur exklusiv für unsere Gäste sichtbar.
                Die zahlreichen und wunderschönen Hochzeitsfotos von unserem Fotografen können nun hier bewundert und heruntergeladen werden.
                Wir würden uns auch sehr freuen, wenn auch ihr eure Momentaufnahmen des Tages hier ausstellt.
                Auch Videos mit dem Handy können hier hochgeladen werden. Vielen Dank und viel Spaß bei diesem schönen Rückblick auf unseren Festtag.
            </Paragraph>
            <Box component={"form"} onSubmit={addSearchTag}>
                <TextField
                    ref={searchRef}
                    variant={"outlined"}
                    fullWidth
                    id={"searchTag"}
                    name={"searchTag"}
                    placeholder={"#search for tag"}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Button
                                    aria-label="add search tag"
                                    type={"submit"}
                                >
                                    Add Search Tag
                                </Button>
                            </InputAdornment>
                    }}

                />
                <List component={"ul"} sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    p: 0.5,
                    m: 0,
                }}>
                    {searchArray.map((chip, i) => (<ListItem sx={{width: 'auto', p: 1}} key={i}>
                        <Chip
                            label={chip}
                            onDelete={removeSearchTag(chip)}
                        />
                    </ListItem>))}
                </List>
            </Box>
            {imageListLoading && <LinearProgress/>}

            {/* ----------- Trauung ----------*/}
            <ImageListWrapper title={"Trauung"} list={imageRefListTrauung} count={displayImageCountAsObject.current.trauungCount} type={"trauung"}
                              handleUploadClick={handleUploadClick}
                              isDialogOpen={isDialogOpen}
                              mdDown={mdDown}
                              smDown={smDown}
                              searchArray={searchArray}
                              setSelectedRef={setSelectedRef} />

            {/* ----------- Agape ----------*/}
            <ImageListWrapper title={"Agape"} list={imageRefListAgape} count={displayImageCountAsObject.current.agapeCount} type={"agape"}
                              handleUploadClick={handleUploadClick}
                              isDialogOpen={isDialogOpen}
                              mdDown={mdDown}
                              smDown={smDown}
                              searchArray={searchArray}
                              setSelectedRef={setSelectedRef} />

            {/* ----------- Essen ----------*/}
            <ImageListWrapper title={"Abendessen"} list={imageRefListEssen} count={displayImageCountAsObject.current.essenCount} type={"essen"}
                              handleUploadClick={handleUploadClick}
                              isDialogOpen={isDialogOpen}
                              mdDown={mdDown}
                              smDown={smDown}
                              searchArray={searchArray}
                              setSelectedRef={setSelectedRef} />

            {/* ----------- Party ----------*/}
            <ImageListWrapper title={"Party"} list={imageRefListParty} count={displayImageCountAsObject.current.partyCount} type={"party"}
                              handleUploadClick={handleUploadClick}
                              isDialogOpen={isDialogOpen}
                              mdDown={mdDown}
                              smDown={smDown}
                              searchArray={searchArray}
                              setSelectedRef={setSelectedRef} />

            {/* ----------- Fotobox ----------*/}
            <ImageListWrapper title={"Fotobox"} list={imageRefListBox} count={displayImageCountAsObject.current.boxCount} type={"box"}
                              handleUploadClick={handleUploadClick}
                              isDialogOpen={isDialogOpen}
                              mdDown={mdDown}
                              smDown={smDown}
                              searchArray={searchArray}
                              setSelectedRef={setSelectedRef} />

            {/* ----------- Gästebuch ----------*/}
            <ImageListWrapper title={"Gästebuch"} list={imageRefListBuch} count={displayImageCountAsObject.current.buchCount} type={"buch"}
                              handleUploadClick={handleUploadClick}
                              isDialogOpen={isDialogOpen}
                              mdDown={mdDown}
                              smDown={smDown}
                              searchArray={searchArray}
                              setSelectedRef={setSelectedRef} />

            {/* ----------- Unkategorisiert ----------*/}
            <ImageListWrapper title={"Unkategorisiert"} list={imageRefList} count={displayImageCountAsObject.current.missingCount} noType
                              handleUploadClick={handleUploadClick}
                              isDialogOpen={isDialogOpen}
                              mdDown={mdDown}
                              smDown={smDown}
                              searchArray={searchArray}
                              setSelectedRef={setSelectedRef} />

            <ImageUploadButton id={"upload-default"} handleUploadClick={handleUploadClick()} isFab sx={{
                position: 'fixed',
                bottom: t => t.spacing(2),
                right: t => t.spacing(2),
                zIndex: t => t.zIndex.drawer + 2
            }}/>

            <Box ref={loader} />

        </PagePaper>
        { uploadCounter > 0 && <Box sx={{
            p: 1,
            backgroundColor: '#fff',
            position: "fixed",
            bottom: t => t.spacing(1),
            left: t => t.spacing(1),
            right: t => t.spacing(10),
        }}>
            <Typography>Uploaded {finishUploadCounter / 100} von {uploadCounter / 100} Bilder</Typography>
            <Typography>Da die Bilder noch konvertiert werden kann es etwas dauern bis diese nach dem upload ersichtlich sind.</Typography>
            <Typography>Nach vollständigem upload und etwas abwarten, dann sollte man die Seite neuladen.</Typography>
            <Typography>Neuladen der Seite unterbricht den Upload. Bitte vorsichtig handeln </Typography>
            { uploadPercent > 0 && <LinearProgress variant="buffer" value={normalise(uploadCounter, finishUploadCounter)} valueBuffer={normalise(uploadPercentMax, uploadPercent)}/>}
        </Box> }
        <Backdrop open={!!selectedRef} sx={{p: 1, zIndex: t => t.zIndex.drawer + 3}} onClick={() => setSelectedRef(null)}>
            <Fab
                sx={{
                    position: 'absolute',
                    top: t => t.spacing(2),
                    right: t => t.spacing(2),
                    zIndex: t => t.zIndex.drawer + 7
                }}
                color={"secondary"}
                aria-label="upload"
                onClick={() => setSelectedRef(null)}
            >
                <Icon>close</Icon>
            </Fab>
            <Fab
                component={"span"}
                sx={{
                    position: 'fixed',
                    bottom: t => t.spacing(6),
                    right: t => t.spacing(2),
                    zIndex: t => t.zIndex.drawer + 7
                }}
                color="primary"
                aria-label="download"
                onClick={startDownload}
            >
                <Icon>download</Icon>
            </Fab>
            {selectedRef && (selectedRef.name.includes('preview') ?
                <FirebaseVideo firebaseRef={selectedRef}
                               videoRefList={imageRefList.concat(imageRefListEssen, imageRefListParty, imageRefListTrauung, imageRefListAgape)
                               .filter(f => f.contentType.includes("video"))}/> :
                <FirebaseImage height firebaseRef={imageRefListAll.find(i => selectedRef.fullPath.startsWith(i.ref.fullPath.split(".")[0]))?.ref ?? null} alt={selectedRef.name}/>)}
            {selectedRef && (selectedRef.name.includes('preview') ?
                <FirebaseVideoMeta
                    firebaseRef={selectedRef}
                    openDialog={() => setIsDialogOpen(true)}
                    handleClose={(del) => {
                        setIsDialogOpen(false);
                        if (!!del) setSelectedRef(null)
                    }}
                    editable={getUploader(selectedRef.fullPath) === `#${user.displayName}`}
                    videoRefList={imageRefList.concat(imageRefListEssen, imageRefListParty, imageRefListTrauung, imageRefListAgape)
                    .filter(f => f.contentType.includes("video"))}
                /> :
                <FirebaseImageMeta
                    firebaseRef={selectedRef}
                    openDialog={() => setIsDialogOpen(true)}
                    handleClose={(del) => {
                        setIsDialogOpen(false);
                        if (!!del) setSelectedRef(null)
                    }}
                    editable={getUploader(selectedRef.fullPath) === `#${user.displayName}`}
                />)}
                <Box sx={{position: "fixed", top: 0 , bottom: 0, left: 0, right: 0, zIndex: t => t.zIndex.drawer + 6, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <IconButton onClick={prevImage(selectedRef)}><Icon sx={{color: "white", fontSize: '2em'}}>arrow_left</Icon></IconButton>
                    <IconButton onClick={nextImage(selectedRef)}><Icon sx={{color: "white", fontSize: '2em'}}>arrow_right</Icon></IconButton>
                </Box>
        </Backdrop>
    </Block>
}

const ImageListWrapper = ({list,title, handleUploadClick, searchArray, count, isDialogOpen, smDown, mdDown, setSelectedRef,type, noType}) => {

    return <>
        {list.length > 0 &&
        <HeaderThreeNoImage title={<span>{title} {!noType && <ImageUploadButton id={"upload-"+type} handleUploadClick={handleUploadClick(type)}/>}</span>}/>
        }
        <ImageList variant="masonry" cols={!smDown ? !mdDown ? 3 : 2 : 1} gap={8} sx={{p: 1}}>
            {list.filter(f => f.contentType.includes('image')).filter(filterForTags(searchArray)).slice(0, count)
            .map((item, i) => (
                <ImageListItem key={i} onClick={() => !isDialogOpen ? setSelectedRef(item.ref) : null}
                               sx={{display: 'inline-flex', width: '100%', justifyContent: 'center', '&:hover': {cursor: 'pointer', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px'}}}>
                    <FirebaseImage firebaseRef={item.ref} alt={item.name} playable={item.name.includes("preview")}/>
                </ImageListItem>
            ))}
        </ImageList>
        </>
}

export default Gallery;
