import React, {useEffect, useState} from "react";
import {Button, Container, Divider, Paper, styled} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import {useAuth} from "../../components/AuthProvider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {CHECK_LIST, EMPTY_GUEST, EMPTY_PERSON, GUEST_LIST_REF} from "../../common/constants";
import {child, onValue, set} from "firebase/database";
import {useSnackbar} from 'notistack';
import Block from "../../components/Block";

const StyledInput = styled(TextField)(({theme}) => ({
    marginBottom: theme.spacing(2),
}))

const Tromise = () => {
    const {enqueueSnackbar} = useSnackbar();
    const {code, user} = useAuth();
    const [guestData, setGuestData] = useState(EMPTY_GUEST);

    useEffect(() => {
        if (code) {
            onValue(child(GUEST_LIST_REF, code), snap => {
                if (!snap.exists()) return;
                const data = snap.val();
                if (!data.promiseNames || data.promiseNames.length === 0) {
                    for (let i = 0; i < data.invitations; i++) {
                        if (!Array.isArray(data.promiseNames)) data.promiseNames = [];
                        data.promiseNames.push({...EMPTY_PERSON});
                    }
                }
                setGuestData({...EMPTY_GUEST, ...data});
                set(child(GUEST_LIST_REF, `${data.key}/promiseNames`), data.promiseNames).then();
            })
        }
    }, [code]);

    const handleChangeEditGuestDataGuest = (index) => (e) => {
        const {name, value, checked} = e.target;
        if (CHECK_LIST.indexOf(name) > -1) {
            const nextGuestData = {...guestData};
            nextGuestData.promiseNames[index][name] = checked;
            return setGuestData({...nextGuestData})
        }
        const nextGuestData = {...guestData};
        nextGuestData.promiseNames[index][name] = value;
        return setGuestData({...nextGuestData})
    }

    const addPersonToList = (e) => {
        setGuestData({
            ...guestData,
            promiseNames: [...guestData.promiseNames, {...EMPTY_PERSON}]
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await set(child(GUEST_LIST_REF, `${guestData.key}/promiseNames`), guestData.promiseNames)
            enqueueSnackbar('Gespeichert und abgeschickt', {
                variant: 'success',
            })
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            enqueueSnackbar('Fehler beim Speichern und Senden', {
                variant: 'error',
            })
        }
    }

    const rejectOneInvitation = i => () => {
        const nextList = guestData.promiseNames;
        nextList.splice(i, 1);
        setGuestData({...guestData, promiseNames: [...nextList]});
    }

    const rejectInvitation = async () => {
        try {
            await set(child(GUEST_LIST_REF, `${guestData.key}/refusal`), true);
            enqueueSnackbar('Schade das du abgesagt hast! Die Absage wurde uns geschickt', {
                variant: 'info',
            })
        }catch(e){
            enqueueSnackbar('Beim senden der Absage ist etwas schiefgelaufen. Probiere es noch einmal, oder melde dich bei uns.', {
                variant: 'error',
            })
        }
    }

    return <Block>
        <Container maxWidth={"md"} sx={{
            maxHeight: t => `calc(100% - ${t.spacing(4)})`,
        }}>
            <Paper elevation={5}
                   sx={{
                       paddingTop: t => t.spacing(2),
                       paddingBottom: t => ({
                           xs: t.spacing(8),
                           md: t.spacing(4),
                       }),
                       paddingLeft: t => t.spacing(4),
                       paddingRight: t => t.spacing(4),
                       width: '100%',
                       overflow: "auto",
                       maxHeight: '100%',
                       boxSizing: 'border-box'
                   }}>
                <Typography variant={"h2"} component={"h1"}
                            sx={{marginBottom: 3, fontSize: {xs: '2rem', sm: '2.5rem'}, textAlign: 'right', fontFamily: '"Darker Grotesque"'}}>EINLADUNG</Typography>
                <Typography variant={"h2"} sx={{marginBottom: 1, fontSize: {xs: '2.25rem', sm: '2.7rem'}}}>{user.displayName},</Typography>
                <Typography variant={"p"} sx={{display: 'block', textAlign: 'left', marginBottom: 4, fontSize: '1.3em'}}>
                    wir laden {guestData.invitations > 1 ? 'euch' : 'dich'} zu unserer Hochzeit am Donnerstag, den <span style={{whiteSpace:'nowrap', fontSize: '1.1em'}}><b>11. August 2022</b></span> ein. <br/>
                    Der schönste Tag unseres Lebens soll auch für unsere Gäste etwas Besonderes sein, weshalb
                    wir {guestData.invitations > 1 ? 'euch' : 'dich'} in unser Planung mit einbinden wollen.
                    Aus diesem Grund bitten wir {guestData.invitations > 1 ? 'euch' : 'dich'} folgendes zu tun:

                    <ol>
                        <li>Fülle bitte weiter unten, bei einer Zusage, für jede eingeladene Person ein Tischkärtchen aus.</li>
                        <li>Markiere zu welchen Teilen des Events wer kommen kann.</li>
                        <li>Wenn jemand nicht kommen kann, dann drücke den Button ABSAGEN beim jeweiligen Tischkärtchen.</li>
                        <li>Falls du deine Meinung ändern solltest oder dich verdrückt hast, dann füge mit dem Button ein neues Tischkärtchen wieder hinzu.</li>
                        <li>Damit alles sicher abgelegt wird, vergiss nicht DATEN SPEICHERN zu drücken.</li>
                        <li>Gäste mit dem identen Link / Einladungscode können die Daten für die Einladung bearbeiten.</li>
                    </ol>
                </Typography>
                <Typography variant={"p"} sx={{display: 'block', textAlign: 'left', marginBottom: 4, fontSize: '1.3em'}}>
                    Da wir noch an der genauen Hochzeitsplanung arbeiten, können wir keine Details nennen. Der grobe Rahmen wird voraussichtlich wie folgt
                    aussehen:
                    <ol>
                        <li>Die TRAUUNG findet um 14 Uhr statt. Um 13:30 Uhr bitten wir unsere Gäste ihre Plätze einzunehmen.</li>
                        <li>Im Anschluss folgt die AGAPE bis etwa 18 Uhr. </li>
                        <li>Das darauffolgende Abendessen wird gegen 22 Uhr enden. </li>
                        <li>Danach folgt unser 1. Tanz, Spiel, Spaß und Tanzmusik.</li>
                    </ol>
                </Typography>
                <Typography variant={"p"} sx={{display: 'block', textAlign: 'left', marginBottom: 4, fontSize: '1.3em'}}>
                    Gerne sind wir, bei Fragen, auch telefonisch erreichbar.<br/>
                    Auf einen unvergesslichen Tag, freuen sich
                </Typography>
                <Typography variant={"h4"} sx={{textAlign: 'right'}}>
                    Florian & Ramona
                </Typography>
                <Divider sx={{marginTop: 2, marginBottom: 2}}/>
                {!guestData.refusal && <Typography variant={"p"} sx={{fontSize: '2rem', marginBottom: 2, display: 'block'}}>Anzahl eingeladener
                    Personen: {guestData.invitations}</Typography>}
                {!guestData.refusal && <Typography variant={"p"} sx={{fontSize: '2rem', marginBottom: 2, display: 'block'}}>
                    Gezählte
                    Zusagen: {guestData.promiseNames?.length > 0 ? guestData.promiseNames.reduce((a, p) => a + (p.marriage || p.agape || p.dinner || p.party ? 1 : 0), 0) : "Komplett abgesagt"}
                    </Typography>}
                {!guestData.refusal && <Box component={"form"}
                                            noValidate
                                            autoComplete="on"
                                            onSubmit={handleSubmit}>
                    {guestData?.promiseNames?.map((guest, i) => (
                        <Paper key={i} elevation={2} sx={{p: 2, boxSizing: 'border-box', marginTop: 1, marginBottom: 2}}>
                            <Typography variant={"h5"}>Tischkärtchen für Gast {(i + 1)}</Typography>
                            <FormGroup sx={{marginTop: 1}}>
                                <StyledInput
                                    id={`fullName-${i}`}
                                    label={"Text für Tischkarte, Gast " + (i + 1)}
                                    type="text"
                                    name="fullName"
                                    value={guest.fullName}
                                    onChange={handleChangeEditGuestDataGuest(i)}
                                    autoComplete="fullName"
                                    variant="outlined"
                                    size={"medium"}
                                    fullWidth
                                />
                            </FormGroup>

                            <FormGroup row>
                                <FormControlLabel
                                    control={<Checkbox name={"marriage"} checked={guest.marriage} onChange={handleChangeEditGuestDataGuest(i)}/>}
                                    label="TRAUUNG"
                                    labelPlacement="start"/>
                                <FormControlLabel control={<Checkbox name={"agape"} checked={guest.agape} onChange={handleChangeEditGuestDataGuest(i)}/>}
                                                  label="AGAPE"
                                                  labelPlacement="start"/>
                                <FormControlLabel control={<Checkbox name={"dinner"} checked={guest.dinner} onChange={handleChangeEditGuestDataGuest(i)}/>}
                                                  label="ESSEN"
                                                  labelPlacement="start"/>
                                <FormControlLabel control={<Checkbox name={"party"} checked={guest.party} onChange={handleChangeEditGuestDataGuest(i)}/>}
                                                  label="PARTY"
                                                  labelPlacement="start"/>
                            </FormGroup>
                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                                <Button sx={{minWidth: 160}} variant={"outlined"} color={"error"} onClick={rejectOneInvitation(i)}>Absagen</Button>
                            </Box>
                        </Paper>))}
                    {guestData?.promiseNames?.length === 0 && <Box>
                        <Typography variant={"p"} sx={{fontSize: '2rem', marginBottom: 2, display: 'block'}}>
                            Du hast alle Tischkärtchen gelöscht, wenn du komplett absagen willst dann bitte unten noch mal auf KOMPLETT ABSAGEN drücken, damit das
                            bei uns auch richtig ankommt.
                        </Typography>
                    </Box>}
                    <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', gap: 16, marginTop: 16}}>
                        {guestData?.promiseNames.length < guestData.invitations &&
                        <Button sx={{minWidth: 160}} startIcon={<Icon>add</Icon>} onClick={addPersonToList} variant={"outlined"}>Tischkärtchen
                            hinzufügen</Button>}
                        <Button sx={{minWidth: 160}} variant={"outlined"} color={"primary"} type={"submit"}>Daten Speichern und Senden</Button>
                        {guestData?.promiseNames?.length === 0 &&
                        <Button sx={{minWidth: 160}} variant={"outlined"} color={"error"} onClick={rejectInvitation}>Komplett Absagen</Button>
                        }
                    </div>
                </Box>}
                {guestData.refusal && <Box>
                    <Typography variant={"p"} sx={{fontSize: '2rem', marginBottom: 2, display: 'block'}}>
                        {guestData.invitations > 1 ? 'Ihr' : 'Du'} {guestData.invitations > 1 ? 'habt' : 'hast'} leider schon abgesagt,
                        wenn {guestData.invitations > 1 ? 'ihr' : 'du'} einen Fehler gemacht {guestData.invitations > 1 ? 'habt' : 'hast'} und doch zur Hochzeit
                        kommen {guestData.invitations > 1 ? 'möchtet' : 'möchtest'} {guestData.invitations > 1 ? 'meldet' : 'melde'} {guestData.invitations > 1 ? 'euch' : 'dich'} bitte
                        bei uns persönlich.</Typography>
                </Box>}
            </Paper>
        </Container>
    </Block>
}

export default Tromise;
