/**
 * Returns a Number between -1 and 2.
 * -1: is an Error
 * 0 : has given a refusal
 * 1 : has seen the Page
 * 2 : has given an Answers
 * @param row is the Data set of one Inventation
 * @returns {number}
 */
export const hasPromise = row => {
    if (row.refusal) return 0;

    const marriageCount = row.promiseNames?.reduce((a, p) => a + (p.marriage ? 1 : 0), 0) ?? 0
    const agapeCount = row.promiseNames?.reduce((a, p) => a + (p.agape ? 1 : 0), 0) ?? 0
    const dinnerCount = row.promiseNames?.reduce((a, p) => a + (p.dinner ? 1 : 0), 0) ?? 0
    const partyCount = row.promiseNames?.reduce((a, p) => a + (p.party ? 1 : 0), 0) ?? 0
    if (marriageCount > 0 || agapeCount > 0 || dinnerCount > 0 || partyCount > 0) return 2;

    if (row.promiseNames?.length > 0) return 1;

    return -1
}

export const hasMeal = row => {
    const soupCount = row.meal?.reduce((a, p) => a + (p.soup !== "" ? 1 : 0), 0) ?? 0
    const mainCount = row.meal?.reduce((a, p) => a + (p.main !== "" ? 1 : 0), 0) ?? 0
    if (soupCount > 0 || mainCount > 0) return 2;
    if (row.meal?.length > 0) return 1;
    return -1
}

export const getMealByName = (row, name) =>{
    return row.meal?.find(m => m.name === name);
}

export const missingNames = row => {
    if (row.promiseNames && row.promiseNames.length > 0){
        return !row.promiseNames.every((element , index) => !!element.fullName && element.fullName?.trim() !== "");
    }
}
