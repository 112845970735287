import React from "react";
import Typography from "@mui/material/Typography";

const HeaderOneNoImage = ({title}) => {
    return <Typography
        variant={"h2"}
        component={"h1"}
        sx={{
            marginBottom: 3,
            fontSize: {xs: '1.75rem', md: '2.25rem'},
            textAlign: 'right',
            fontFamily: '"Darker Grotesque"'}}
    >
        {title}</Typography>


}

export default HeaderOneNoImage;


