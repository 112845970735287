import React from "react";
import {styled} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const TextBlock = styled(Box)(({theme}) => ({
    fontSize: 96,
    textShadow: '4px 4px 4px #aaa',
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1,
    boxSizing: 'border-box',
    padding: theme.spacing(4)
}));

const NoMatch = () => {

    return <TextBlock>
        <Typography variant={"h1"}>Hier gibt es nix zu sehen</Typography>
    </TextBlock>
}

export default NoMatch;
