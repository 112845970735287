import React from "react";
import Typography from "@mui/material/Typography";

const HeaderOneImage = ({title}) => {
    return <Typography
        variant={"h2"}
        component={"h1"}
        sx={{
            fontSize: {xs: '2.5rem', md: '3rem'},
            display: 'inline-block'
        }}
    >
        {title}
    </Typography>

}

export default HeaderOneImage;


