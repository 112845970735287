import React, {useEffect, useState} from "react";
import {getDownloadURL} from "firebase/storage";
import loadingGif from "../../assets/images/giphy.gif";
import {Box, Icon} from "@mui/material";

const FirebaseImage = ({firebaseRef, alt, height, playable}) => {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getDownloadURL(firebaseRef).then(res => {
            setUrl(res);
            setLoading(false);
        })
    }, [firebaseRef]);

    if (!url) return <img/>
    if (loading) return <img src={loadingGif}/>
    if (playable) {
        return <Box sx={{position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img
                style={{maxWidth: '100%', maxHeight: height ? '100%' : 'auto'}}
                src={url}
                alt={alt}
                loading="lazy"
            />
            <Icon sx={{position: 'absolute', color: 'white', fontSize: '3em'}}>play_arrow</Icon>
        </Box>
    }

    return <img
        style={{maxWidth: '100%', maxHeight: height ? '100%' : 'auto'}}
        src={url}
        alt={alt}
        loading="lazy"
    />

}

export default FirebaseImage;
