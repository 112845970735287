import React from "react";
import {Chip, Link} from "@mui/material";

import Block from "../../components/Block";
import PagePaper from "../../components/PagePaper";
import HeaderOneNoImage from "../../components/HeaderOneNoImage";
import HeaderTwoNoImage from "../../components/HeaderTwoNoImage";
import Paragraph from "../../components/Paragraph";

const specialFontFamily = [
    '"Sacramento"', 'san-serif'
].join(',');

const Kontakt = () => {

    return <Block>
        <PagePaper>
            <HeaderOneNoImage title={"KONTAKTE"}/>
            <HeaderTwoNoImage title={"Noch Fragen?"}/>
            <Paragraph>Bei Fragen, Wünschen oder Anregungen sind wir gerne für euch erreichbar.</Paragraph>
            <Paragraph>Ihr könnt uns direkt per WhatsApp oder Anruf kontaktieren:</Paragraph>
            <Paragraph>
                <div>Braut</div>
                <div style={{fontSize: '1.5em', fontFamily: specialFontFamily}}>Ramona</div>
                <Link sx={{textDecoration: 'none'}} href="tel:+436604840763">
                    <Chip sx={{fontSize: '1em'}} variant="outlined" color="primary"
                          label={"+43 660 48 40 763"}/>
                </Link>
            </Paragraph>
            <Paragraph>
                <div>Bräutigam</div>
                <div style={{fontSize: '1.5em', fontFamily: specialFontFamily}}>Florian</div>
                <Link sx={{textDecoration: 'none'}} href="tel:+436601472547">
                    <Chip sx={{fontSize: '1em'}} variant="outlined" color="primary"
                          label={"+43 660 147 25 47"}/>
                </Link>
            </Paragraph>
            <Paragraph>
                <div>Trauzeugin</div>
                <div style={{fontSize: '1.5em', fontFamily: specialFontFamily}}>Daniela</div>
                <Link sx={{textDecoration: 'none'}} href="tel:+4369911097383">
                    <Chip sx={{fontSize: '1em'}} variant="outlined" color="primary"
                          label={"+43 699 1109 73 83"}/>
                </Link>
            </Paragraph>
            <Paragraph>
                <div>Trauzeuge</div>
                <div style={{fontSize: '1.5em', fontFamily: specialFontFamily}}>Tom</div>
                <Link sx={{textDecoration: 'none'}} href="tel:+436646178629">
                    <Chip sx={{fontSize: '1em'}} variant="outlined" color="primary"
                          label={"+43 664 617 86 29"}/>
                </Link>
            </Paragraph>
        </PagePaper>
    </Block>
}

export default Kontakt;
