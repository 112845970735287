import {getDatabase, ref} from "firebase/database";
import {firebase} from "../firebase";
import React from "react";

export const EMPTY_GUEST = {
    name: '',
    mail: '',
    site: '',
    invitations: 1,
    promiseNames: [],
    taxi: {
        feedback: '',
        selected: 'empty'
    },
    refusal: false
}

export const EMPTY_PERSON = {
    fullName: '',
    marriage: false,
    agape: false,
    dinner: false,
    party: false,
    child: false,
    baby: false,
    maybe: false,
}

export const EMPTY_MEAL = {
    name: '',
    soup: '',
    main: '',
    desert: true,
    child: false,
    age: 0,
    chair: false,
}

export const CHECK_LIST = ['party', 'marriage', 'agape', 'dinner', 'baby', 'child', 'maybe'];

export const mealText = {
    soupOne: 'Hochzeitssuppe mit Grießnockerl, Leberknödel und/oder Frittaten mit frischem Schnittlauch',
    SoupTwo : 'Pikante Spitzpaprikacremesuppe mit Zitronen-Thymianschaum',
    mainOne: 'Wiener Schnitzel mit Petersilienkartoffeln, Preiselbeeren und Ketchup dazu ein Salatteller mit Gurken, Karotten, Tomaten und Vogerlsalat',
    mainTwo: 'Steirische Hühnerbrust mit Mozzarella und Basilikum gefüllt auf Safran-Risotto dazu Rosmarinsauce ',
    mainThree: 'Safran-Risotto dazu Pepperonata und Kräutersalat ',
    "" : 'Keine Auswahl'
}

const db = getDatabase(firebase);
export const GUEST_LIST_REF = ref(db, 'guests');
export const THANK_LIST_REF = ref(db, 'thanksList');
export const THANK_YOU_REF = ref(db, 'thankYou');
export const GALLERY_REF = ref(db, 'gallery');
