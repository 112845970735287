import React, {useEffect, useState} from "react";
import {
    Collapse, FormControl, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemText, MenuItem, Paper, Select, Tooltip
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {child, onValue, set} from "firebase/database";
import TableFooter from "@mui/material/TableFooter";
import Icon from "@mui/material/Icon";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import {customAlphabet} from 'nanoid'
import QRCode from 'qrcode.react';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {CHECK_LIST, EMPTY_GUEST, EMPTY_PERSON, GUEST_LIST_REF, mealText} from "../../common/constants";
import ReactDOM from 'react-dom';
import {useSnackbar} from "notistack";
import Block from "../../components/Block";
import Divider from "@mui/material/Divider";
import {hasPromise, hasMeal, getMealByName} from "../../common/statelessFunctions";
import {Link} from "react-router-dom";

const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 10)

const GuestList = () => {
    const {enqueueSnackbar} = useSnackbar();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const [data, setData] = useState([])
    const [editGuest, setEditGuest] = useState(EMPTY_GUEST)
    const [errorMessage, setErrorMessage] = useState('')
    const [filter, setFilter] = useState('')
    const [filterSite, setFilterSite] = useState('')
    const [filterChecked, setFilterChecked] = useState('all')
    const [filterExtra, setFilterExtra] = useState('all')

    useEffect(() => {
        onValue(GUEST_LIST_REF, snap => {
            if (!snap.exists()) return;
            const newList = []
            snap.forEach(guestSnap => {
                newList.push(guestSnap.val());
            })
            setData(newList);
        })
    }, [])

    const handleClose = () => {
        setIsModalOpen(false);
        setIsEditMode(false);
        setErrorMessage('');
        setEditGuest(EMPTY_GUEST);
    }

    const handleOpenModal = () => {
        setIsModalOpen(true);
    }

    const handleOpenEdit = (guest) => () => {
        setIsEditMode(true);
        const list = guest.promiseNames?.map(p => ({...EMPTY_PERSON, ...p})) ?? [];
        setEditGuest({...EMPTY_GUEST, ...guest, promiseNames: list});
        handleOpenModal();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!editGuest.name || editGuest.name === '' || editGuest.invitations < 1) return setErrorMessage('Name muss ausgefüllt und Einladungen muss über 0 sein')
            if (!isEditMode) editGuest.key = nanoid();
            await set(child(GUEST_LIST_REF, editGuest.key), editGuest)
            setIsModalOpen(false);
            setIsEditMode(false);
            setEditGuest(EMPTY_GUEST);
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
        }
    }

    const handleChangeEditGuestData = (e) => {
        const {name, value, checked} = e.target;
        if (name === 'refusal') {
            return setEditGuest({...editGuest, [name]: checked})
        }
        if (name === 'invitations') return setEditGuest({...editGuest, [name]: parseInt(value)})
        return setEditGuest({...editGuest, [name]: value})
    }

    const handleRemove = (index) => e => {
        const nextGuestData = {...editGuest};
        nextGuestData.promiseNames?.splice(index,1);
        return setEditGuest({...nextGuestData});
    }


    const handleChangeEditGuestDataGuest = (index) => (e) => {
        const {name, value, checked} = e.target;
        if (CHECK_LIST.indexOf(name) > -1) {
            const nextGuestData = {...editGuest};
            nextGuestData.promiseNames[index][name] = checked;
            return setEditGuest({...nextGuestData})
        }
        const nextGuestData = {...editGuest};
        nextGuestData.promiseNames[index][name] = value;
        return setEditGuest({...nextGuestData})
    }

    const handleDelete = (row) => async e => {
        if (window.confirm(`Sicher ${row.name} - ${row.key} löschen?`)) await set(child(GUEST_LIST_REF, row.key), null);
    }

    const addPersonToList = (e) => {
        setEditGuest({
            ...editGuest,
            promiseNames: [...editGuest.promiseNames, {...EMPTY_PERSON}]
        })
    }
    const downloadQR = id => () => {
        const element = <QRCode
            id={id + 'img'}
            value={`wedding.hotarek-ribel.at/overview?code=${id}`}
            size={240}
            level={"L"}
            includeMargin={true}
        />
        const parentQrNode = document.getElementById('qrimg')
        ReactDOM.render(element, parentQrNode);
        const canvas = document.getElementById(id + 'img');
        const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = id + ".png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        parentQrNode.removeChild(canvas);
    }

    const nameMailFilter = text => r => r?.mail?.toLowerCase().includes(text.toLowerCase()) ||
        r?.name?.toLowerCase().includes(text.toLowerCase());

    const checkedFilter = text => r => {
        if (text === "all") return true;
        const promiseCheckNumber = hasPromise(r);
        const refusalCheck = text === "refusal" && promiseCheckNumber === 0;
        const seenCheck = text === "seen" && promiseCheckNumber === 1;
        const checkedCheck = text === "checked" && promiseCheckNumber === 2;
        const missingCheck = text === "missing" && promiseCheckNumber === -1;

        return refusalCheck || seenCheck || checkedCheck || missingCheck;
    }

    const extraFilter = text => r => {
        if (text === "all") return true;
        if (text === "child") return r.promiseNames?.reduce((a, p) => a || p.child, false);
        if (text === "baby") return r.promiseNames?.reduce((a, p) => a || p.baby, false);
        if (text === "maybe") return r.promiseNames?.reduce((a, p) => a || p.maybe, false);
    }

    const siteFilter = text => r => text === "" || r?.site === text;

    return <Block>

        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650, width: '100%'}}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={8}>
                            <Typography variant={"h1"}>Gäste-Liste</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell/>
                        <TableCell/>
                        <TableCell align="right">Name</TableCell>
                        <TableCell align="right">Personen</TableCell>
                        <TableCell align="right">Trauung</TableCell>
                        <TableCell align="right"><Icon>settings</Icon></TableCell>
                        <TableCell/>
                        <TableCell/>
                        <TableCell/>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <IconButton onClick={() => setIsFilterOpen(b => !b)}>
                                <Icon>filter_list</Icon>
                            </IconButton>
                        </TableCell>
                        <TableCell colSpan={7}>
                            <TextField
                                variant={"outlined"}
                                fullWidth
                                value={filter}
                                placeholder={"Filter nach Name und Erreichbar"}
                                size={"small"}
                                onChange={(e) => setFilter(e.target.value)}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="clear filter"
                                                onClick={() => setFilter("")}
                                                edge="end"
                                                disabled={filter === ""}
                                            >
                                                <Icon>close</Icon>
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={8}>
                            <Collapse in={isFilterOpen}>
                                <FormControl fullWidth variant="outlined" sx={{my: 1}}>
                                    <InputLabel id="select-promise-filter">Zusagen</InputLabel>
                                    <Select
                                        id="select-promise-filter"
                                        value={filterChecked}
                                        label={"Zusagen"}
                                        displayEmpty
                                        onChange={(e) => setFilterChecked(e.target.value)}
                                    >
                                        <MenuItem value="all">
                                            Alle
                                        </MenuItem>
                                        <MenuItem value={"checked"}>Zusagen</MenuItem>
                                        <MenuItem value={"seen"}>Gesehen</MenuItem>
                                        <MenuItem value={"missing"}>Fehlend</MenuItem>
                                        <MenuItem value={"refusal"}>Absagen</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth variant="outlined" sx={{my: 1}}>
                                    <InputLabel id="select-site-filter" shrink={true} sx={{backgroundColor: 'white'}}>Zugehörigkeit</InputLabel>
                                    <Select
                                        value={filterSite}
                                        label={"Zugehörigkeit"}
                                        displayEmpty
                                        onChange={(e) => setFilterSite(e.target.value)}
                                    >
                                        <MenuItem value="">
                                            Seite Wählen
                                        </MenuItem>
                                        <MenuItem value={"Ramona"}>Ramona</MenuItem>
                                        <MenuItem value={"Florian"}>Florian</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth variant="outlined" sx={{my: 1}}>
                                    <InputLabel id="select-promise-filter">Extra</InputLabel>
                                    <Select
                                        id="select-extra-filter"
                                        value={filterExtra}
                                        label={"Extra"}
                                        displayEmpty
                                        onChange={(e) => setFilterExtra(e.target.value)}
                                    >
                                        <MenuItem value="all">
                                            Alle
                                        </MenuItem>
                                        <MenuItem value={"baby"}>mit Babys</MenuItem>
                                        <MenuItem value={"child"}>mit Kinder</MenuItem>
                                        <MenuItem value={"maybe"}>Vielleicht</MenuItem>
                                    </Select>
                                </FormControl>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.filter(checkedFilter(filterChecked)).filter(extraFilter(filterExtra)).filter(siteFilter(filterSite)).filter(nameMailFilter(filter))
                    .map((row, i) => (
                        <TableRow
                            key={row.name + i}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                <Box sx={{whiteSpace: 'nowrap', display: "flex", alignItem: 'center'}}>
                                    <Box sx={{pr: 1}}>{i + 1}.</Box>
                                    {hasPromise(row) !== 0 ?
                                        (hasPromise(row) !== 1 ?
                                                (hasPromise(row) !== 2 ?
                                                        <Icon>disabled_visible</Icon> :
                                                        <Icon color={"success"}>done_all</Icon>
                                                ) :
                                                <Icon color={"info"}>face</Icon>
                                        ) :
                                        <Icon color={"error"}>clear</Icon>}
                                    <Box sx={{px: 1}}>:</Box>
                                    {hasMeal(row) !== 0 ?
                                        (hasMeal(row) !== 1 ?
                                                (hasMeal(row) !== 2 ?
                                                        <Icon>disabled_visible</Icon> :
                                                        <Icon color={"success"}>done_all</Icon>
                                                ) :
                                                <Icon color={"info"}>face</Icon>
                                        ) :
                                        <Icon color={"error"}>clear</Icon>}
                                </Box>
                            </TableCell>
                            <TableCell align="right">
                                <CopyToClipboard text={`https://wedding.hotarek-ribel.at/overview?code=${row.key}`}
                                                 onCopy={() => enqueueSnackbar("In Zwischenablage kopiert", {variant: 'success'})}>
                                    <Tooltip title={`Kopiere: https://wedding.hotarek-ribel.at/overview?code=${row.key}`}>
                                        <IconButton><Icon>file_copy</Icon></IconButton>
                                    </Tooltip>
                                </CopyToClipboard>
                            </TableCell>
                            <TableCell align="right">{row.name}</TableCell>
                            <TableCell align="right">{row.invitations}</TableCell>
                            <TableCell align="right">
                                {row.promiseNames?.reduce((a, p) => a + (p.marriage ? 1 : 0), 0) ?? 0}
                            </TableCell>
                            <TableCell align="right" sx={{'& *': {textShadow: 'none'}}}>
                                <IconButton onClick={handleOpenEdit(row)}>
                                    <Icon>edit</Icon>
                                </IconButton>
                            </TableCell>
                            <TableCell align="right" sx={{'& *': {textShadow: 'none'}}}>
                                <IconButton onClick={handleDelete(row)}>
                                    <Icon>delete</Icon>
                                </IconButton>
                            </TableCell>
                            <TableCell align="right" sx={{'& *': {textShadow: 'none'}}}>
                                <IconButton component={Link} to={`/thankYou/${row.key}`}>
                                    <Icon>mail</Icon>
                                </IconButton>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
                                    <Tooltip title={`QR Code für https://wedding.hotarek-ribel.at/overview?code=${row.key} herunterladen`}>
                                        <Box>
                                            <QRCode
                                                id={row.key}
                                                value={`https://wedding.hotarek-ribel.at/overview?code=${row.key}`}
                                                size={80}
                                                level={"L"}
                                                includeMargin={true}
                                                onClick={downloadQR(row.key)}
                                            />
                                        </Box>
                                    </Tooltip>
                                    <Typography variant={"p"} sx={{fontSize: '1em'}}>{row.key}</Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell colSpan={13}>
                            <IconButton onClick={handleOpenModal}>
                                <Icon>add_circle_outline</Icon>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell/>
                        <TableCell/>
                        <TableCell component="th" scope="row" align="right">
                            Filter Summe
                        </TableCell>
                        <TableCell align="right">{data.filter(checkedFilter(filterChecked)).filter(extraFilter(filterExtra)).filter(siteFilter(filterSite))
                        .filter(nameMailFilter(filter))
                        .reduce((a, e) => a + (e.invitations ?? 0), 0)}</TableCell>
                        <TableCell
                            align="right">{data.filter(checkedFilter(filterChecked)).filter(extraFilter(filterExtra)).filter(siteFilter(filterSite))
                        .filter(nameMailFilter(filter))
                        .reduce((a, e) => a + (e?.promiseNames?.reduce((acc, p) => acc + (p.marriage ? 1 : 0), 0) ?? 0), 0)}</TableCell>
                        <TableCell/>
                        <TableCell/>
                        <TableCell/>

                    </TableRow>
                    <TableRow>
                        <TableCell />
                        <TableCell/>
                        <TableCell component="th" scope="row" align="right">
                            SUMMEN
                        </TableCell>
                        <TableCell align="right">{data.reduce((a, e) => a + (e.invitations ?? 0), 0)}</TableCell>
                        <TableCell
                            align="right">{data.reduce((a, e) => a + (e?.promiseNames?.reduce((acc, p) => acc + (p.marriage ? 1 : 0), 0) ?? 0), 0)}</TableCell>
                        <TableCell/>
                        <TableCell/>
                        <TableCell/>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>

        <Dialog open={isModalOpen} onClose={handleClose}>
            <DialogTitle>Create / Edit - Gast</DialogTitle>
            <Box
                component="form"
                noValidate
                autoComplete="on"
                onSubmit={handleSubmit}
            >
                <DialogContent>
                    <TextField
                        id="name"
                        label="Name des Gast / der Gäste"
                        type="text"
                        name="name"
                        value={editGuest.name}
                        onChange={handleChangeEditGuestData}
                        autoComplete="name"
                        variant="standard"
                        size={"medium"}
                        autoFocus
                        fullWidth
                        error={!!errorMessage}
                        helperText={errorMessage}
                    />
                    <TextField
                        id="mail"
                        label="E-Mail des Gast / der Gäste"
                        type="text"
                        name="mail"
                        value={editGuest.mail}
                        onChange={handleChangeEditGuestData}
                        autoComplete="mail"
                        variant="standard"
                        size={"medium"}
                        fullWidth
                        error={!!errorMessage}
                        helperText={errorMessage}
                    />
                    <TextField
                        id="count"
                        label="Anzahl Eingeladene Personen"
                        type="number"
                        name="invitations"
                        value={editGuest.invitations}
                        onChange={handleChangeEditGuestData}
                        autoComplete="count"
                        variant="standard"
                        size={"medium"}
                        fullWidth
                        error={!!errorMessage}
                    />
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="select-site-label">Eingeladen von</InputLabel>
                        <Select
                            labelId="select-site-label"
                            id="select-site"
                            value={editGuest.site}
                            name="site"
                            onChange={handleChangeEditGuestData}
                            label="site"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={"Ramona"}>Ramona</MenuItem>
                            <MenuItem value={"Florian"}>Florian</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControlLabel control={<Checkbox name={"refusal"} color={"error"} checked={editGuest.refusal} onChange={handleChangeEditGuestData}/>}
                                      label="Abgesagt"/>

                    {!editGuest.refusal && editGuest.promiseNames.map((guest, i) => (
                        <FormGroup key={i} sx={{padding: 1, boxSizing: 'border-box', marginTop: 1}}>
                            <TextField
                                id={`fullName-${i}`}
                                label={"Name für Tischkarte, Gast " + (i + 1)}
                                type="text"
                                name="fullName"
                                value={guest.fullName}
                                onChange={handleChangeEditGuestDataGuest(i)}
                                autoComplete="fullName"
                                variant="standard"
                                size={"medium"}
                                fullWidth
                            />
                            <FormControlLabel control={<Checkbox name={"marriage"} checked={guest.marriage} onChange={handleChangeEditGuestDataGuest(i)}/>}
                                              label="Trauung"/>
                            <FormControlLabel control={<Checkbox name={"agape"} checked={guest.agape} onChange={handleChangeEditGuestDataGuest(i)}/>}
                                              label="Agape"/>
                            <FormControlLabel control={<Checkbox name={"dinner"} checked={guest.dinner} onChange={handleChangeEditGuestDataGuest(i)}/>}
                                              label="Essen"/>
                            <FormControlLabel control={<Checkbox name={"party"} checked={guest.party} onChange={handleChangeEditGuestDataGuest(i)}/>}
                                              label="Party"/>
                            <Divider/>
                            <FormControlLabel control={<Checkbox name={"maybe"} checked={guest.maybe} onChange={handleChangeEditGuestDataGuest(i)}/>}
                                              label="Vielleicht"/>
                            <Divider />
                            {getMealByName(editGuest, guest.fullName) && <Box>
                                <Box>Suppe: {mealText[getMealByName(editGuest, guest.fullName).soup] ?? 'Keine Auswahl getroffen'}</Box>
                                <Box>Main: {mealText[getMealByName(editGuest, guest.fullName).main] ?? 'Keine Auswahl getroffen'}</Box>
                                <Box>Child: {getMealByName(editGuest, guest.fullName).child ? 'Ja' : 'Nein'}</Box>
                                {getMealByName(editGuest, guest.fullName).child && <Box>Hochstuhl: {getMealByName(editGuest, guest.fullName).chair ? 'Ja' : 'Nein'}</Box>}
                                {getMealByName(editGuest, guest.fullName).child && <Box>Alter: {getMealByName(editGuest, guest.fullName).age}</Box>}
                            </Box>}
                            <Button variant={"text"} onClick={handleRemove(i)}>Löschen</Button>
                        </FormGroup>))}
                    {editGuest.promiseNames.length < editGuest.invitations &&
                    <IconButton disabled={editGuest.refusal} onClick={addPersonToList}><Icon>add</Icon></IconButton>}
                </DialogContent>
                <DialogActions>
                    <Button variant={"text"} color={"neutral"} onClick={handleClose}>abbrechen</Button>
                    <Button variant={"text"} color={"primary"} type={"submit"}>speichern</Button>
                </DialogActions>
            </Box>
        </Dialog>
    </Block>
}

export default GuestList;
