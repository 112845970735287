import React, {useEffect, useState} from "react";
import {Avatar, Divider, List, ListItemButton, styled, Chip} from "@mui/material";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import {Link, Outlet} from 'react-router-dom';
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import {useAuth} from "../AuthProvider";
import Drawer from "@mui/material/Drawer";
import {useNavigation} from "../NavigationProvider";

const NavigationBlock = styled(Box)(({theme}) => ({
    margin: 0,
    width: '100%',
    height: '100vh',
    position: 'relative',
    zIndex: 2,
    background: '#fff',
}))

const StyledListItemText = styled(ListItemText)(({theme}) => ({
    display: 'inline-flex',
    justifyContent: 'center',
    '& .MuiTypography-root': {
        fontSize: 24,
        textTransform: 'uppercase',
        fontFamily: [
            '"Darker Grotesque"', 'san-serif'
        ].join(','),
        textShadow: '2px 2px 2px #aaa',
    }
}))

const MenuBox = styled(Box)(({theme}) => ({
    position: 'fixed',
    top: theme.spacing(2),
    left: theme.spacing(2),
    zIndex: theme.zIndex.drawer + 2,
}))

const currentOverview = 1;
const currentPromise = 1;
const currentLocation = 1;
const currentMeal = 2;
const currentTimeline = 1;
const currentPresents = 1;
const currentHotels = 0;
const currentTaxi = 1;
const currentKontakt = 1;
const currentFoto = 1;
const currentGallery = 1;
const currentThanks = 1;
const currentThankYou = 0;


const Navigation = () => {
    const {isOpen, openNavigation, closeNavigation, isHidden} = useNavigation();
    const {user,onLogout} = useAuth();
    const [newOverview, setNewOverview] = useState(false);
    const [newPromise, setNewPromise] = useState(false);
    const [newLocation, setNewLocation] = useState(false);
    const [newMeal, setNewMeal] = useState(false);
    const [newTimeline, setNewTimeline] = useState(false);
    const [newPresents, setNewPresents] = useState(false);
    const [newHotels, setNewHotels] = useState(false);
    const [newTaxi, setNewTaxi] = useState(false);
    const [newKontakt, setNewKontakt] = useState(false);
    const [newFoto, setNewFoto] = useState(false);
    const [newGallery, setNewGallery] = useState(false);
    const [newThanks, setNewThanks] = useState(false);
    const [newThankYou, setNewThankYou] = useState(false);

    useEffect(() => {
        const overview = localStorage.getItem('overview');
        const promise = localStorage.getItem('promise');
        const location = localStorage.getItem('location');
        const meal = localStorage.getItem('meal');
        const timeline = localStorage.getItem('timeline');
        const presents = localStorage.getItem('presents');
        const hotels = localStorage.getItem('hotels');
        const taxi = localStorage.getItem('taxi');
        const kontakt = localStorage.getItem('kontakt');
        const foto = localStorage.getItem('foto');
        const gallery = localStorage.getItem('gallery');
        const thanks = localStorage.getItem('thanks');
        const thankYou = localStorage.getItem('thankYou');

        if( currentOverview !== 0 && (!overview ||  parseInt(overview) < currentOverview )) setNewOverview(true);
        if( currentPromise !== 0 && (!promise ||  parseInt(promise) < currentPromise )) setNewPromise(true);
        if( currentLocation !== 0 && (!location ||  parseInt(location) < currentLocation )) setNewLocation(true);
        if( currentMeal !== 0 && (!meal ||  parseInt(meal) < currentMeal ) )setNewMeal(true);
        if( currentTimeline !== 0 && (!timeline ||  parseInt(timeline) < currentTimeline )) setNewTimeline(true);
        if( currentPresents !== 0 && (!presents ||  parseInt(presents) < currentPresents )) setNewPresents(true);
        if( currentHotels !== 0 && (!hotels ||  parseInt(hotels) < currentHotels )) setNewHotels(true);
        if( currentTaxi !== 0 && (!taxi ||  parseInt(taxi) < currentTaxi ) )setNewTaxi(true);
        if( currentKontakt !== 0 && (!kontakt ||  parseInt(kontakt) < currentKontakt )) setNewKontakt(true);
        if( currentFoto !== 0 && (!foto ||  parseInt(foto) < currentFoto )) setNewFoto(true);
        if( currentGallery !== 0 && (!gallery ||  parseInt(gallery) < currentGallery )) setNewGallery(true);
        if( currentThanks !== 0 && (!thanks ||  parseInt(thanks) < currentThanks )) setNewThanks(true);
        if( currentThankYou !== 0 && (!thankYou ||  parseInt(thankYou) < currentThankYou )) setNewThankYou(true);
    }, [])

    const onClose =  element => e => {
        switch(element){
            case 'overview' : {
                localStorage.setItem(element, currentOverview.toString(10));
                setNewOverview(false);
                break ;
            }
            case 'promise': {
                localStorage.setItem(element, currentPromise.toString(10));
                setNewPromise(false);
                break ;
            }
            case 'location': {
                localStorage.setItem(element, currentLocation.toString(10));
                setNewLocation(false);
                break;
            }
            case 'meal': {
                localStorage.setItem(element, currentMeal.toString(10));
                setNewMeal(false);
                break;
            }
            case 'timeline': {
                localStorage.setItem(element, currentTimeline.toString(10));
                setNewTimeline(false);
                break;
            }
            case 'presents': {
                localStorage.setItem(element, currentPresents.toString(10));
                setNewPresents(false);
                break;
            }
            case 'hotels': {
                localStorage.setItem(element, currentHotels.toString(10));
                setNewHotels(false);
                break;
            }
            case 'taxi': {
                localStorage.setItem(element, currentTaxi.toString(10));
                setNewTaxi(false);
                break;
            }
            case 'kontakt': {
                localStorage.setItem(element, currentKontakt.toString(10));
                setNewKontakt(false);
                break;
            }
            case 'foto': {
                localStorage.setItem(element, currentFoto.toString(10));
                setNewFoto(false);
                break;
            }
            case 'gallery': {
                localStorage.setItem(element, currentGallery.toString(10));
                setNewGallery(false);
                break;
            }
            case 'thanks': {
                localStorage.setItem(element, currentThanks.toString(10));
                setNewThanks(false);
                break;
            }
            case 'thankYou': {
                localStorage.setItem(element, currentThankYou.toString(10));
                setNewThankYou(false);
                break;
            }
        }
        closeNavigation();
    }

    const handleLogout = e => {
        onClose(e);
        onLogout();
    }

    const toggleMenu = () => {
        return isOpen ? closeNavigation() : openNavigation();
    }

    return <>
        {!isHidden && <MenuBox sx={{displayPrint: 'none'}}>
            <Avatar sx={{backgroundColor: "white"}}>
                <IconButton onClick={toggleMenu}> {isOpen ? <Icon>menu_open</Icon> : <Icon>menu</Icon> } </IconButton>
            </Avatar>
        </MenuBox>}
        <Outlet/>
        <Drawer
            anchor={"bottom"}
            open={isOpen}
            onClose={onClose}
        >
            <Box sx={{minHeight: '100vh', width: '100%'}}>
                <NavigationBlock>
                    <List>
                        <ListItemButton component={Link} to="/overview" onClick={onClose("overview")}>
                            <StyledListItemText primary={'Startseite'}
                                                secondary={newOverview &&<Chip sx={{ml: 2, position:'absolute'}} variant="outlined" color="success" size="small" label={"new"} />}/>
                        </ListItemButton>
                        <Divider />

                        <ListItemButton component={Link} to="/promise" onClick={onClose("promise")}>
                            <StyledListItemText primary={'Einladung'}
                                                secondary={newPromise &&<Chip sx={{ml: 2, position:'absolute'}} variant="outlined" color="success" size="small" label={"new"} />}/>
                        </ListItemButton>
                        <Divider />

                        <ListItemButton component={Link} to="/location" onClick={onClose("location")}>
                            <StyledListItemText primary={'Location & Anreise'}
                                                secondary={newLocation &&<Chip sx={{ml: 2, position:'absolute'}} variant="outlined" color="success" size="small" label={"new"} />}/>
                        </ListItemButton>
                        <Divider />

                        <ListItemButton component={Link} to="/meal" onClick={onClose("meal")}>
                            <StyledListItemText primary={'Menüauswahl'}
                                                secondary={newMeal && <Chip sx={{ml: 2, position:'absolute'}} variant="outlined" color="success" size="small" label={"new"} />}/>
                        </ListItemButton>
                        <Divider />

                        <ListItemButton component={Link} to="/timeline" onClick={onClose("timeline")}>
                            <StyledListItemText primary={'Tagesablauf'}
                                                secondary={newTimeline && <Chip sx={{ml: 2, position:'absolute'}} variant="outlined" color="success" size="small" label={"new"} />}/>
                        </ListItemButton>
                        <Divider />

                        <ListItemButton component={Link} to="/presents" onClick={onClose("presents")}>
                            <StyledListItemText primary={'Unsere Wünsche'}
                                                secondary={newPresents && <Chip sx={{ml: 2, position:'absolute'}} variant="outlined" color="success" size="small" label={"new"} />}/>
                        </ListItemButton>
                        <Divider />

                        <ListItemButton component={Link} to="/hotels" onClick={onClose("hotels")}>
                            <StyledListItemText primary={'Unterkünfte'}
                                                secondary={newHotels &&<Chip sx={{ml: 2, position:'absolute'}} variant="outlined" color="success" size="small" label={"new"} />}/>
                        </ListItemButton>
                        <Divider />

                        <ListItemButton component={Link} to="/taxi" onClick={onClose("taxi")}>
                            <StyledListItemText primary={'Abreise: Taxiabfrage'}
                                                secondary={newTaxi && <Chip sx={{ml: 2, position:'absolute'}} variant="outlined" color="success" size="small" label={"new"} />}/>
                        </ListItemButton>
                        <Divider />

                        <ListItemButton component={Link} to="/foto" onClick={onClose("foto")}>
                            <StyledListItemText primary={'Fotos'}
                                                secondary={newFoto && <Chip sx={{ml: 2, position:'absolute' }} variant="outlined" color="success" size="small" label={"new"} />}/>
                        </ListItemButton>
                        <Divider />

                        <ListItemButton component={Link} to="/gallery" onClick={onClose("gallery")}>
                            <StyledListItemText primary={'Gallery'}
                                                secondary={newGallery && <Chip sx={{ml: 2, position:'absolute' }} variant="outlined" color="success" size="small" label={"new"} />}/>
                        </ListItemButton>
                        <Divider />

                        <ListItemButton component={Link} to="/thanks" onClick={onClose("thanks")}>
                            <StyledListItemText primary={'Danksagungen'}
                                                secondary={newThanks && <Chip sx={{ml: 2, position:'absolute' }} variant="outlined" color="success" size="small" label={"new"} />}/>
                        </ListItemButton>
                        <Divider />

                        <ListItemButton component={Link} to="/thankYou" onClick={onClose("thankYou")}>
                            <StyledListItemText primary={'Dankeskarte'}
                                                secondary={newThankYou && <Chip sx={{ml: 2, position:'absolute' }} variant="outlined" color="success" size="small" label={"new"} />}/>
                        </ListItemButton>
                        <Divider />

                        <ListItemButton component={Link} to="/kontakt" onClick={onClose("kontakt")}>
                            <StyledListItemText primary={'Kontaktdaten'}
                                                secondary={newKontakt &&<Chip sx={{ml: 2, position:'absolute' }} variant="outlined" color="success" size="small" label={"new"} />}/>
                        </ListItemButton>
                        <Divider />
                        {/*
                            - Kontakt / Organisation
                        */}
                        {user?.isAdmin && <ListItemButton component={Link} to="/list" onClick={onClose("list")}>
                            <StyledListItemText primary={'Gäste-Liste'}/>
                        </ListItemButton>}
                        {user?.isAdmin && <Divider /> }
                        {user?.isAdmin && <ListItemButton component={Link} to="/tabel" onClick={onClose("tabel")}>
                            <StyledListItemText primary={'Tischordnung'}/>
                        </ListItemButton>}
                        {user?.isAdmin && <Divider /> }
                        {user?.isAdmin && <ListItemButton component={Link} to="/stats" onClick={onClose("stats")}>
                            <StyledListItemText primary={'Überblick'}/>
                        </ListItemButton>}
                        {user?.isAdmin && <Divider /> }
                        {user && <ListItemButton onClick={handleLogout}>
                            <StyledListItemText primary={'Logout'}/>
                        </ListItemButton>}
                        <Divider />

                    </List>
                </NavigationBlock>
            </Box>
        </Drawer>
    </>
}

export default Navigation;
