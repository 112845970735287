import React from "react";
import {Paper, Typography} from "@mui/material";
import {useAuth} from "../../components/AuthProvider";
import Block from "../../components/Block";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import {useNavigation} from "../../components/NavigationProvider";
//import {CountDown} from "../../components";
import {Link} from "react-router-dom";

const Overview = () => {
    const {openNavigation} = useNavigation();
    const {user} = useAuth();

    return <Block>
        <Paper elevation={5} sx={{
            p: 5,
            textAlign: 'center',
            position: 'relative',
        }}>

            <Typography variant={"p"} sx={{fontSize: {xs: '2rem', sm: '2rem', md: '2rem', lg: '2.5rem', xl: '3rem'}}}>{user?.displayName}</Typography>
            <Typography variant={"h1"} sx={{marginTop: t => t.spacing(3), marginBottom: '-25px', fontSize: {xs: '4.4rem', sm: '4.7rem', md: '5.2rem', lg: '5.7rem', xl: '6.2rem'}}}>
                Danke
            </Typography>
            <Typography variant={"p"} sx={{fontSize: {xs: '2rem', sm: '2rem', md: '2rem', lg: '2.5rem', xl: '3rem'}, display: 'inline-block', marginBottom: 5}}>
                für euer Kommen.
            </Typography><br />
            <Typography variant={"p"} sx={{fontSize: {xs: '2rem', sm: '2rem', md: '2rem', lg: '2.5rem', xl: '3rem'}, display: 'inline-block', marginBottom: 5}}>
                In der <Link to={'/gallery'} style={{color: 'black', textDecoration: 'none', fontWeight: 'bold'}}>Gallerie</Link> findet <br/> ihr
                unsere Fotos vom
            </Typography><br />
            <Typography variant={"p"} sx={{fontSize: {xs: '2rem', sm: '2rem', md: '2rem', lg: '2.5rem', xl: '3rem'}, display: 'inline-block', marginBottom: 5}}>
                <span style={{marginRight: 5}}>11.</span>
                <Icon sx={{fontSize: {xs: '2rem', sm: '2rem', md: '2rem', lg: '2.5rem', xl: '3rem'}, transform: 'rotate(-90deg)', paddingRight: '6px', boxSizing: 'border-box'}} className={"rotating"}>all_inclusive</Icon>
                <span style={{marginLeft: 5}}>.22</span>
            </Typography>
            <Button onClick={openNavigation} variant={"contained"} sx={{position: 'absolute', bottom: t => t.spacing(1.5), right: t => t.spacing(1.5)}} >Menu</Button>

        </Paper>
    </Block>
}

export default Overview;


/*

Es sind noch
                <CountDown />
                bis zur Hochzeit am

 */
