import React, {useEffect, useState} from "react";
import {getMetadata, updateMetadata, deleteObject} from "firebase/storage";
import {
    Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, ImageListItemBar, InputAdornment, TextField, Typography
} from "@mui/material";
import {child, set} from "firebase/database";
import {GALLERY_REF} from "../../common/constants";
import {useAuth} from "../AuthProvider";

const FirebaseImageMeta = ({firebaseRef, editable, openDialog, handleClose}) => {
    const [meta, setMeta] = useState(null);
    const [editMeta, setEditMeta] = useState(false);
    const [tagString, setTagString] = useState('');
    const {user} = useAuth();

    useEffect(() => {
        getMetadata(firebaseRef).then(res => {
            setMeta(res);
        })
    }, [firebaseRef]);

    const getUploader = () => {
        return `#${meta.fullPath.split('/').filter(t => !t.includes('images'))[0]}`;
    }

    const getTags = () => {
        return meta?.customMetadata?.tags?.split('#')
        .filter(t => t.trim() !== '')
        .map(t => '#' + t)
        .join(' ') ?? '#none';
    }

    const getTagsList = () => {
        return meta?.customMetadata?.tags?.split('#')
        .filter(t => t.trim() !== '')
        .map((t, i) => <span style={{display: 'block'}} key={i}><IconButton disabled={t === "flonaforever"}
                                                                            onClick={removeTag(t)}><Icon>delete</Icon></IconButton> #{t}</span>) ?? null;
    }

    const editImage = (event) => {
        event.stopPropagation();
        openDialog();
        setEditMeta(true);
    }

    const addTag = _ => {
        _.stopPropagation();
        setTagString('');
        meta.customMetadata.tags = (meta.customMetadata.tags ?? '#flonaforever') + `#${tagString}`;
        setMeta({...meta});
    }

    const removeTag = (str) => _ => {
        _.stopPropagation();
        if (str === 'flonaforever') return;
        meta.customMetadata.tags = '#' + meta.customMetadata.tags.split('#').filter(s => s !== str).join('#');
        setMeta({...meta});
    }

    const save = _ => {
        _.stopPropagation();
        updateMetadata(firebaseRef, meta).then(r => {
            set(child(GALLERY_REF, 'update'), (new Date()).toISOString() + `#${getUploader()}`)
            .catch(e => console.error(e));
            setEditMeta(false);
            handleClose();
        }).catch(e => console.log(e));
    }

    const cancel = _ => {
        _.stopPropagation();
        getMetadata(firebaseRef).then(res => {
            setMeta(res);
            setEditMeta(false);
            handleClose();
        }).catch(e => console.error(e));
    }

    const deleteImage = _ => {
        _.stopPropagation();
        deleteObject(firebaseRef).then(r => {
            set(child(GALLERY_REF, 'update'), (new Date()).toISOString() + `#${getUploader()}`)
            .catch(e => console.error(e));
            setEditMeta(false);
            handleClose(true);
        }).catch(e => console.error(e));
    }

    const setCategory = (data) => _ => {
        _.stopPropagation();
        meta.customMetadata.category = data;
        setMeta({...meta});
    }

    if (!meta) return null;

    return <Box onClick={(e) => e.stopPropagation()}>
        <ImageListItemBar
            title={getUploader()}
            subtitle={getTags()}
            actionIcon={(editable || user.isAdmin) &&
            <IconButton
                sx={{color: 'rgba(255, 255, 255, 0.54)'}}
                aria-label={`edit about ${getUploader()}`}
                onClick={editImage}
            >
                <Icon>edit</Icon>
            </IconButton>
            }
        />
        <Dialog open={editMeta} maxWidth={"sm"}>
            <DialogTitle>Edit</DialogTitle>
            <DialogContent>
                <Typography component={'div'} variant={"body1"}>
                    Uploader: {getUploader()} <br/>
                    Tags: {getTagsList()}<br/>
                    <TextField
                        sx={{mt: 1}}
                        value={tagString}
                        fullWidth
                        label={"Add Tag"}
                        onChange={(e) => setTagString(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><Button variant={"text"} color={"success"}
                                                                                 onClick={addTag}>Add</Button></InputAdornment>,
                        }}
                    />
                </Typography>

            </DialogContent>
            <DialogContent>
                <Typography variant={"body1"}>Zu welchen Zeitabschnitt gehört das Bild ?</Typography>
                <ButtonGroup>
                    <Button variant={meta.customMetadata.category === "trauung" ? "contained" : "outlined"} color={"info"}
                            onClick={setCategory('trauung')}>Trauung</Button>
                    <Button variant={meta.customMetadata.category === "agape" ? "contained" : "outlined"} color={"info"}
                            onClick={setCategory('agape')}>Agape</Button>
                    <Button variant={meta.customMetadata.category === "essen" ? "contained" : "outlined"} color={"info"}
                            onClick={setCategory('essen')}>Essen</Button>
                    <Button variant={meta.customMetadata.category === "party" ? "contained" : "outlined"} color={"info"}
                            onClick={setCategory('party')}>Party</Button>
                    <Button variant={meta.customMetadata.category === "buch" ? "contained" : "outlined"} color={"info"}
                            onClick={setCategory('buch')}>Buch</Button>
                    <Button variant={meta.customMetadata.category === "box" ? "contained" : "outlined"} color={"info"}
                            onClick={setCategory('box')}>Box</Button>
                </ButtonGroup>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"error"} onClick={deleteImage}>Löschen</Button>
                <Button variant={"outlined"} color={"primary"} onClick={cancel}>Abbrechen</Button>
                <Button variant={"contained"} color={"primary"} onClick={save}>Speichern</Button>
            </DialogActions>
        </Dialog>
    </Box>

}

export default FirebaseImageMeta;
